import { Input, constants, Text, SelectMenu } from 'bitrus-web-ui';
import React, { useState } from 'react';
import { dataInputs } from './dataInputs';
import { componentInput } from '../../Utils/Constant/inputs';
import DateTimeHelper from '../../helpers/DateTimeHelper';
import { useOnboarding } from '../../Hooks/useOnboarding';
import { ButtonSubmit } from '../ButtonSubmit';
import { ButtonReset } from '../ButtonReset';

export const AddPerson = () => {
   const { onboardingActions } = useOnboarding();

   const [person, setPerson] = useState({
      birth_place: '',
      nationality: '',
      identification_type: '',
      identification_number: '',
      identification_proof: {
         file_extension: '',
         file_content: '',
      },
      gender: '',
      birth_date: '',
      first_name: '',
      second_name: '',
      first_surname: '',
      second_surname: '',
      is_pep: '',
      curp: '',
   });
   const handleInputChange = (event) => {
      setPerson({
         ...person,
         [event?.target.name]: event?.target.value,
      });
   };
   const Conver2Bse64 = (e) => {
      const file = e?.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
         setPerson({
            ...person,
            [e?.target.name]: {
               file_extension: reader?.result.split(/[\s,/;]+/)[1],
               file_content: reader?.result.split(',')[1],
            },
         });
      };
      reader.readAsDataURL(file);
   };
   const handleSubmit = (e) => {
      e.preventDefault(person.birth_date);
      let newPerson = {
         ...person,
         is_pep: Boolean(person.is_pep),
         birth_date: DateTimeHelper.UTC(person.birth_date),
      };
      onboardingActions.AddPerson(newPerson);
   };
   return (
      <form
         className="sm:m-16 space-y-5 divide-y divide-gray-200"
         onSubmit={handleSubmit}
      >
         <div className="space-y-8 divide-y divide-gray-200">
            <div>
               <div>
                  <Text
                     tag={constants.tagsTypes.P}
                     styles="text-lg leading-6 font-medium text-gray-900"
                     title="Add person"
                  />
               </div>
            </div>
         </div>
         <div className="pt-8">
            <div className="mt-8 grid sm:grid-cols-3 sm:gap-3 md:grid-cols-3 md:gap-3">
               {dataInputs.map((data) => (
                  <div className="mt-1" key={data.key}>
                     {data.comopent === componentInput.SELECT && (
                        <SelectMenu
                           options={data.options}
                           handleInputChange={handleInputChange}
                        />
                     )}
                     {data.comopent === componentInput.INPUT && (
                        <Input
                           require={true}
                           infoInput={{
                              type: data.info.type,
                              name: data.info.name,
                              id: data.info.id,
                           }}
                           label={data.label}
                           styles="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                           handleInputChange={handleInputChange}
                        />
                     )}
                     {data.comopent === componentInput.FILE && (
                        <Input
                           require={true}
                           infoInput={{
                              type: data.info.type,
                              name: data.info.name,
                              id: data.info.id,
                           }}
                           label={data.label}
                           styles="block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm mt-3"
                           handleInputChange={(e) => Conver2Bse64(e)}
                        />
                     )}
                  </div>
               ))}
            </div>
         </div>
         <div className="pt-5">
            <ButtonSubmit />
         </div>
         <div className="pt-5">
            <ButtonReset />
         </div>
      </form>
   );
};
