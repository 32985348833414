import { createSlice } from '@reduxjs/toolkit';

export const financialEntitySlice = createSlice({
   name: 'financialEntity',
   initialState: {
      data: [],
   },
   reducers: {
      setfinancialEntity: (state, action) => {
         state.data = action.payload;
      },
   },
});
export const { setfinancialEntity } = financialEntitySlice.actions;

export default financialEntitySlice.reducer;
