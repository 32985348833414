import { createSlice } from '@reduxjs/toolkit';

export const ConciliationStatus = createSlice({
   name: 'ConciliationStatus',
   initialState: {
      data: {},
   },
   reducers: {
      setDataConciliationStatus: (state, action) => {
         state.data = action.payload;
      },
   },
});
export const { setDataConciliationStatus } = ConciliationStatus.actions;

export default ConciliationStatus.reducer;
