import React, { useEffect, useState } from 'react';
import { UseConciliation } from '../../../Hooks/useConciliation';
import { MainView } from '../../MainView';
import {
   constants,
   Text,
   TableUsers,
   Stats,
   Input,
   Button,
   typeColorsButton,
} from 'bitrus-web-ui';
import { FormatNumberWithCommas } from '../../../helpers/cryptoHelpers';

export const ConciliationsView = () => {
   const {
      conciliationActions,
      OrdersData,
      argsConciliated,
      DataConciliationStatus,
   } = UseConciliation();

   useEffect(() => {
      conciliationActions.getConciliationData();
      conciliationActions.getConciliationStatus();
      conciliationActions.FilterPnlByRange();
   }, []);

   let statsData3 = {
      stats: [
         {
            name: 'PNL',
            stat: `${FormatNumberWithCommas(
               DataConciliationStatus.profit
            )} MXN`,
         },
         {
            name: 'MXN conciliated',
            stat: `${FormatNumberWithCommas(
               DataConciliationStatus.fiat_conciliated
            )} MXN`,
         },
         {
            name: 'MXN pending to be conciliated',
            stat: `${FormatNumberWithCommas(
               DataConciliationStatus.fiat_to_conciliate
            )} MXN`,
         },
         {
            name: 'Number of orders conciliated',
            stat: DataConciliationStatus.orders_conciliated,
         },
         {
            name: 'Number of orders to be conciliated',
            stat: DataConciliationStatus.orders_to_conciliate,
         },
         {
            name: 'Total MXN on orders',
            stat: `${FormatNumberWithCommas(
               DataConciliationStatus.fiat_total_orders
            )} MXN`,
         },
         {
            name: 'Number of failed orders',
            stat: `${DataConciliationStatus.total_failed}`,
         },
         {
            name: 'Number of completed orders',
            stat: DataConciliationStatus.total_completed,
         },
         {
            name: 'Number of orders currently procesing',
            stat: DataConciliationStatus.total_processing,
         },
         {
            name: 'Number of queued orders',
            stat: DataConciliationStatus.total_queued,
         },

         {
            name: 'Number of orders in total',
            stat: DataConciliationStatus.total_orders,
         },
         {
            name: 'USD amount available for conciliations',
            stat: `${FormatNumberWithCommas(
               DataConciliationStatus.usd_available_deposits
            )} USD`,
         },
         {
            name: 'Number of deposits avaible for conciliations',
            stat: DataConciliationStatus.total_available_deposits,
         },
      ],
   };

   return (
      <MainView>
         <div className='md:flex md:items-center md:justify-between'>
            <div className='flex-1 min-w-0 mb-4'>
               <Text title='PNL' tag={constants.tagsTypes.H2} />
            </div>
            <div className='pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between'>
               <Text title='Filter by Date' tag={constants.tagsTypes.H5} />
               <div className='mt-3 flex sm:mt-0 sm:ml-4'>
                  <div className='px-1'>
                     <Input
                        require={true}
                        infoInput={{
                           type: constants.inputType.DATE,
                           name: 'initial_rank',
                           id: 'initial_rank',
                        }}
                        styles='shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
                        handleInputChange={conciliationActions.handleDateChange}
                     />
                  </div>
                  <div className='px-1'>
                     <Input
                        require={true}
                        infoInput={{
                           type: constants.inputType.DATE,
                           name: 'end_rank',
                           id: 'end_rank',
                        }}
                        styles='shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
                        handleInputChange={conciliationActions.handleDateChange}
                     />
                  </div>
                  <div className='px-1'>
                     <Button
                        text='Filter'
                        background={typeColorsButton.PRIMARY_COLOR}
                        size={constants.buttonSizes.MEDIUM}
                        FunctionClick={conciliationActions.FilterPnlByRange}
                     />
                  </div>
               </div>
            </div>
         </div>
         <div className='mt-20 mb-20'>
            <Stats type={constants.typesStats.SIMPLE} data={statsData3} />
         </div>
         {OrdersData?.length === 0 ? (
            <div className=' flex justify-center content-center my-36'>
               <div className='pb-5 border-b border-gray-200'>
                  <Text
                     title='You have no reconciled transactions'
                     tag={constants.tagsTypes.H3}
                  />
               </div>
            </div>
         ) : (
            <div className='my-10'>
               <TableUsers {...argsConciliated} />
            </div>
         )}
      </MainView>
   );
};
