import { configureStore } from '@reduxjs/toolkit';
import OrdersHistory from '../slices/ordersHistory';
import DepositData from '../slices/DepositDataSlice';
import DataListDeposit from '../slices/listDeposit';
import DataDeletePreview from '../slices/DataPreviewSlice';
import DataDepositDelete from '../slices/DataDepositDeleteSlice';
import user from '../slices/loginSlice';
import stats from '../slices/statsSlice';
import tx from '../slices/txSlice';
import users from '../slices/usersSlice';
import ConciliationStatus from '../slices/dataConciliationStatus';
import persons from '../slices/addPPersonSlice';
import externalCompany from '../slices/addExternalCompanySlice';
import company from '../slices/CreateCompanySlice';
import getMe from '../slices/getMe';
import companyType from '../slices/companyTypeSlice';
import companyActivity from '../slices/companyActivitySlice';
import financialEntity from '../slices/financialEntitySlice';
import fundOrigin from '../slices/fundOriginSlice';
import zipCode from '../slices/zipCodeSlice';
import companyId from '../slices/companyIdSlice';

export const store = configureStore({
   reducer: {
      user,
      companyActivity,
      OrdersHistory,
      DepositData,
      DataListDeposit,
      DataDeletePreview,
      DataDepositDelete,
      stats,
      tx,
      users,
      ConciliationStatus,
      financialEntity,
      persons,
      fundOrigin,
      companyType,
      getMe,
      companyId,
      zipCode,
      externalCompany,
      company,
   },
});
