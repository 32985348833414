export const routes = {
   LOGIN: '/login',
   OVERVIEW: '/',
   REPORTS: '/reports',
   USERS: '/users',
   PNL: '/pnl',
   REGISTERDEPOSIT: '/registerdeposit',
   TX_HISTORY: '/transaction_history/:type',
   TX_HISTORY_PARAMS: '/transaction_history/',
   REDIRECT: '/*',
   ERROR: '*',
   WALLETS: '/wallets',
   CONCILIATIONDELETE: '/conciliationsdelete',
   ONBOARDING: '/Onboarding',
};

export const nameRoutes = {
   LOGIN: 'Login',
   OVERVIEW: 'Overview',
   REPORTS: 'Reports',
   USERS: 'Users',
   PNL: 'PNL',
   REGISTERDEPOSIT: 'Register deposit',
   TX_HISTORY: 'Tx_history',
   REDIRECT: 'Redirect',
   WALLETS: 'Wallets',
   CONCILIATIONDELETE: 'Concilitions',
   ONBOARDIN: 'Onboarding',
};
