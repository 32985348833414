import React from 'react';
import { MainView } from '../../MainView';
import { Deposit } from './Deposit';
import { Text, ModalConfirmation, constants, Alert } from 'bitrus-web-ui';
import { useDeposit } from '../../../Hooks/useDeposit';

export const ConciliationView = () => {
   const {
      depositActions,
      open,
      setOpen,
      disable,
      dataDeposit,
      alertMessage,
      alertColor,
      alertType,
   } = useDeposit();

   return (
      <MainView>
         <div className="md:flex md:items-center md:justify-between">
            <div className="flex-1 min-w-0">
               <Text
                  title="Register USD deposits"
                  tag={constants?.tagsTypes.H2}
               />
            </div>
         </div>
         {alertMessage && (
            <div className="mt-20 mb-5">
               <Alert
                  type={alertType}
                  title={alertMessage}
                  color={alertColor}
               />
            </div>
         )}{' '}
         <Deposit
            setOpen={setOpen}
            handleInputChangeDeposit={depositActions?.handleInputChangeDeposit}
            disable={disable}
            dataDeposit={dataDeposit}
         />
         <ModalConfirmation
            succes={true}
            title="Are you sure you want to Confirm of the operation?"
            viewModal={open}
            FunctionCancel={() => setOpen(false)}
            FunctionAction={() => depositActions?.DepositMarket()}
         />
      </MainView>
   );
};
