export const dateFormats = {
   SHORT_DATE: 'DD-MM-YYYY',
   FULL_DATE: 'DD-MM-YYYY HH:mm',
   REGULAR_TIME: 'hh:mm:ss a',
   MILITARY_TIME: 'HH:mm:ss',
   MONTH_DAY: 'MMMM DD',
   YEAR_MONTH_DAY: 'YYYY-MM-DD',
   WEEKDAY_NAME: 'dddd',
   UTC: 'YYYY-MM-DDTHH:MM:SSZ',
};
