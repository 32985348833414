import { createSlice } from '@reduxjs/toolkit';

export const OrdersHistoryList = createSlice({
   name: 'OrdersHistory',
   initialState: {
      data: [],
   },
   reducers: {
      setOrdersHistory: (state, action) => {
         state.data = action.payload;
      },
   },
});
export const { setOrdersHistory } = OrdersHistoryList.actions;

export default OrdersHistoryList.reducer;
