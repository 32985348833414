import moment from 'moment';
import { dateFormats } from '../Utils/Constant/date';

class DateTimeHelper {
   // convert date to YYYY-MM-DD format ie: 2020-12-12

   ToRequestDateString = (date) => {
      return moment(date).format(dateFormats.YEAR_MONTH_DAY);
   };

   ToDate = (date) => {
      return moment(date).toDate(dateFormats.FULL_DATE);
   };

   FirstDayMonth = (date) => {
      return moment(date).startOf('month').format(dateFormats.SHORT_DATE);
   };

   LastDayMonth = (date) => {
      return moment(date).endOf('month').format(dateFormats.SHORT_DATE);
   };

   ToWeekDay = (date) => {
      return moment(date).format(dateFormats.WEEKDAY_NAME);
   };

   ToMonthDay = (date) => {
      return moment(date).format(dateFormats.MONTH_DAY);
   };

   ToLocalDate = (date) => {
      return moment(date).local().format(dateFormats.REGULAR_TIME);
   };

   ToUtcDate = (date) => {
      return moment(date).utc().format(dateFormats.SHORT_DATE);
   };

   LastSevenDays = (date) => {
      return moment(date).subtract(7, 'd').format(dateFormats.SHORT_DATE);
   };
   UTC = (date) => {
      return moment(date).format(dateFormats.UTC);
   };

   ToFullDate = (value = null) => {
      'worklet';
      let date;
      if (value) {
         date = new Date(Number(value));
      } else {
         date = new Date();
      }

      const day = ('0' + date.getDate()).slice(-2);
      const month = ('0' + date.getMonth()).slice(-2);
      const year = date.getFullYear();
      return `${year}-${month}-${day}`;
   };
}

export default new DateTimeHelper();
