import { createSlice } from '@reduxjs/toolkit';

export const zipCodeSlice = createSlice({
   name: 'zipCode',
   initialState: {
      data: [],
   },
   reducers: {
      setZipCode: (state, action) => {
         state.data = action.payload;
      },
   },
});
export const { setZipCode } = zipCodeSlice.actions;

export default zipCodeSlice.reducer;
