import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
   buyOrders,
   deposits,
   deleteDepositPreview,
   deleteDeposit,
   conciliateDeposits,
   conciliationStatus,
} from '../helpers/ApiInterface';
import { CustomError } from './useExeption';
import { setOrdersHistory } from '../slices/ordersHistory';
import { setDepositData } from '../slices/DepositDataSlice';
import { setListDeposit } from '../slices/listDeposit';
import DateTimeHelper from '../helpers/DateTimeHelper';
import { Icons } from 'bitrus-web-ui';
import { setDataDeletePreview } from '../slices/DataPreviewSlice';
import { setDataDepositDelete } from '../slices/DataDepositDeleteSlice';
import { cloneDeep } from 'lodash';
import { setDataConciliationStatus } from '../slices/dataConciliationStatus';
import { getCoinNmaeById } from '../helpers/cryptoHelpers';
import { FormatNumberWithCommas } from '../helpers/cryptoHelpers';

export const UseConciliation = () => {
   const dispatch = useDispatch();
   const OrdersData = useSelector((store) => store.OrdersHistory?.data);
   const DepositData = useSelector((store) => store.DepositData?.data);
   const DataListDeposit = useSelector((store) => store.DataListDeposit?.data);
   const DataDeletePreview = useSelector(
      (store) => store.DataDeletePreview?.data
   );
   const DataDelete = useSelector((store) => store.DataDepositDelete?.data);
   const [showModalPreview, setShowModalPreview] = useState(false);
   const [deleteIdTransaccion, setDeleteIdTransaccion] = useState(0);
   const DataPreview = useSelector((store) => store.DataDeletePreview?.data);
   const DataConciliationStatus = useSelector(
      (store) => store.ConciliationStatus?.data
   );
   const [dataRange, setDataRange] = useState({
      initial_rank: '',
      end_rank: '',
   });

   const handleDateChange = (event) => {
      setDataRange({
         ...dataRange,
         [event?.target.name]: event?.target.value,
      });
   };

   const getHeadersData = (obj) => {
      return Object.keys(obj[0]).map((data) => {
         return { Header: data, accessor: data };
      });
   };

   ///
   const filtterData = () => {
      let data = [];
      OrdersData?.forEach((element) => {
         let fiat_cost_amount = element.fiat_cost_amount;
         let pnl =
            Number.parseFloat(
               element.fiat_income_amount - element.fiat_cost_amount
            ).toFixed(2) + ' MXN';

         data.push({
            filled_at: element.filled_at,
            fiat_amount: `${FormatNumberWithCommas(
               element.fiat_income_amount
            )} MXN`,
            pnl: fiat_cost_amount ? pnl : 'NA',
            is_conciliated:
               element.is_conciliated === true
                  ? Icons.checkCircle()
                  : Icons.XCircle(),
            asset_amount: `${element.asset_amount} ${getCoinNmaeById(
               element.asset_id
            )}`,
            buy_order_status: element.buy_order_status,
            usd_cost_amount: element.usd_cost_amount,
            transaction_id: element.transaction_id,
            fiat_id: element.fiat_id,
            asset_id: element.asset_id,
            liquidity_provider_id: element.liquidity_provider_id,
            lp_client_order_id: element.lp_client_order_id,
            external_id: element.external_id,
            created_at: DateTimeHelper.ToFullDate(new Date(element.created_at)),
            updated_at: DateTimeHelper.ToFullDate(new Date(element.updated_at)),
            id: element.id,
         });
      });
      return data;
   };
   const filterDataListDeposit = () => {
      let data = [];
      DataListDeposit?.forEach((element) => {
         data.push({
            fiat_id: 1,
            fiat_amount: 1234,
            usd_amount: 123,
            liquidity_provider_id: element.liquidity_provider_id,
            id: element.id,
            consumed_at: DateTimeHelper.ToFullDate(
               new Date(element.consumed_at)
            ),
            is_consumed:
               element.is_consumed === true
                  ? Icons.checkCircle()
                  : Icons.XCircle(),
         });
      });
      return data;
   };

   const FilterPnlByRange = () => {
      let fecha = new Date();

      function subtractDays(numOfDays, date = new Date()) {
         date.setDate(date.getDate() - numOfDays);

         return date;
      }

      var DataFilterRange = filtterData().filter(function (a) {
         let date = new Date(a.created_at).toLocaleDateString();
         let InitialRange = fecha.toLocaleDateString();
         let EndRange = subtractDays(28).toLocaleDateString();
         let initial =
            dataRange.initial_rank !== ''
               ? new Date(
                    dataRange.initial_rank.replace(/_|#|-|@|<>/g, '/')
                 ).toLocaleDateString()
               : EndRange;
         let ends =
            dataRange.end_rank !== ''
               ? new Date(
                    dataRange.end_rank.replace(/_|#|-|@|<>/g, '/')
                 ).toLocaleDateString()
               : InitialRange;

         return date >= initial && date <= ends;
      });
      return DataFilterRange;
   };

   let argsConciliated = {
      columns: OrdersData.length ? getHeadersData(filtterData()) : [],
      data: FilterPnlByRange(),
   };

   const getConciliationData = async () => {
      try {
         const tradeOrdersHistory = await buyOrders();
         const depoistList = await deposits();

         dispatch(setOrdersHistory(tradeOrdersHistory));
         dispatch(setDepositData(depoistList));
      } catch (err) {
         throw new CustomError(err);
      }
   };

   const getConciliationListDeposits = async () => {
      try {
         const depoistList = await deposits();
         dispatch(setListDeposit(depoistList));
      } catch (err) {
         throw new CustomError(err);
      }
   };

   const getPreviewDeleteDeposit = async (value) => {
      setDeleteIdTransaccion(value);
      try {
         const dataDeletePreview = await deleteDepositPreview(value);
         setShowModalPreview(true);
         dispatch(setDataDeletePreview([dataDeletePreview]));
      } catch (err) {
         throw new CustomError(err);
      }
   };

   const deleteDeposits = async (value) => {
      try {
         const deleteSelectedDeposit = await deleteDeposit(value);
         dispatch(setDataDepositDelete(deleteSelectedDeposit));
         setShowModalPreview(false);
      } catch (err) {
         throw new CustomError(err);
      }
   };

   const getConciliationStatus = async () => {
      try {
         const res = await conciliationStatus();
         dispatch(setDataConciliationStatus(res));
      } catch (err) {
         throw new CustomError(err);
      }
   };

   const tiggerConciliation = async () => {
      try {
         await conciliateDeposits();
      } catch (err) {
         throw new CustomError(err);
      }
   };

   const conciliationActions = {
      getConciliationData,
      getConciliationListDeposits,
      getPreviewDeleteDeposit,
      deleteDeposits,
      tiggerConciliation,
      getConciliationStatus,
      FilterPnlByRange,
      handleDateChange,
   };

   return {
      filtterData,
      OrdersData,
      DepositData,
      DataListDeposit,
      filterDataListDeposit,
      DataDeletePreview,
      showModalPreview,
      setShowModalPreview,
      deleteIdTransaccion,
      DataDelete,
      conciliationActions,
      argsConciliated,
      DataPreview,
      DataConciliationStatus,
   };
};
