export const getCoinNmaeById = (id) => {
   switch (id) {
      case 1: {
         return 'ETH';
      }
      case 2: {
         return 'USDT';
      }
      default:
         return 'unrecognized';
   }
};

export const FormatNumberWithCommas = (amount, decimals = 2) => {
   return parseFloat(amount)
      .toFixed(decimals)
      ?.toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
