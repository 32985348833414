export const base = process.env.REACT_APP_BACKEND_URL_DEV;

// LOGIN

export const accesToken = `login/access-token`;

export const refreshToken = `login/refresh-token`;

export const mfaToken = (email) => `login/mfa-token/${email}`;

export const validatePin = (email) => `login/validate-pin/${email}`;

export const testToken = `login/test-token`;

export const passwordRecovery = (email) => `login/password-recovery/${email}`;

export const accountRecovery = (email) => `login/account-recovery/${email}`;

export const resendOtp = (email) => `login/resend-otp/${email}`;

export const resetPassword = `login/reset-password/`;

// USERS

export const getUsers = (skipNumber, limitNumber) =>
   `users/?skip=${skipNumber}&limit=${limitNumber}`;

export const postUsers = `users/`;

export const acceptTos = `users/accept-tos`;

export const sendKycInformation = `users/me/send-kyc-information`;

export const getUserMe = `users/me`;

export const putUserMe = `users/me`;

export const setUsersPhone = `users/me/set-user-phone`;

export const registerDevice = `users/me/register-device`;

export const removeDevice = `users/me/remove-device`;

export const signUp = `users/me/sign-up`;

export const validateChannels = `users/me/validate-channels`;

export const generateOtp = `users/me/generate-otp`;

export const getUser = (user_id) => `users/${user_id}`;

export const putUser = (user_id) => `users/${user_id}`;

export const setUserPhone = (user_id) => `users/${user_id}/set-user-phone`;

export const cryptobalance = `users/me/cryptobalance`;

export const usersMeTx = `users/me/tx`;

export const userTx = (user_id) => `users/${user_id}/tx`;

export const reviewKycRiskProfile = (user_id) =>
   `users/${user_id}/review-kyc-risk-profile`;

export const listenMatiStep = `users/listen-mati-step`;

// ADMIN

export const transferColdStorage = `admin/providers/fireblocks/transfer_to_cold_storage
`;
export const balanceAdmin = `admin/providers/balance`;
export const adminTx = (start, end) =>
   `admin/providers/tx?start_date=${start}&end_date=${end}`;

export const getTotalUsersBalance = `admin/get-total-users-balance`;

export const conciliateDeposits = `admin/conciliate_deposits`;

// export const deleteDepositPreview = `admin/delete_deposit/preview`;
export const deleteDepositPreview = (deposit_id) =>
   `admin/delete_deposit/preview?deposit_id=${deposit_id}`;

export const deleteDeposit = (deposit_id) =>
   `admin/delete_deposit?deposit_id=${deposit_id}`;

export const buyOrders = `admin/buy_orders`;

export const deposits = `admin/deposits`;

export const conciliationStatus = `admin/conciliation_status`;

export const setDefaultWithdraw = (asset_network_id) =>
   `admin/asset-network/${asset_network_id}/set_default_withdraw_fee`;

export const getDefaultWithdraw = (
   asset_network_id
) => `admin/asset-network/${asset_network_id}/get_default_withdraw_fee
`;
export const setAssetNetworkActivity = (
   asset_network_id
) => `admin/asset-network/${asset_network_id}/set-asset-network-activity
`;
export const setMarketActivity = (
   market_id
) => `admin/market/${market_id}/set-market-activity
`;
export const generateUifReport = `admin/generate_UIF_report`;

export const generatePLreports = `admin/generate_p_and_l_reports`;

export const marketmaker = `marketmaker/register-fiat-deposit`;

//COMPANY

export const addExternalCompany = (id) => `company/${id}/add-external-company`;

export const addPeson = (id) => `company/${id}/add-person`;

export const createUpdateCompany = (company_id) => `company/${company_id}
`;
export const createSharedHolder = (company_id) =>
   `company/${company_id}/set-shareholders`;

export const createEmployes = (company_id) =>
   `company/${company_id}/set-employees`;

export const createLegalRepresentative = (company_id) =>
   `company/${company_id}/set-legal-representative`;

export const setShareholdersExternal = (company_id, external_company_id) =>
   `company/${company_id}/external-company/${external_company_id}/set-shareholders`;

export const companyType = `metadata/company-type`;

export const companyActivity = `metadata/company-activity`;

export const financialEntity = `metadata/financial_entity`;

export const fundOrigin = `metadata/fund-origin`;

export const zipCode = (zip_code_id) => `metadata/zip-code/${zip_code_id}`;
