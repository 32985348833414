import React, { useState } from 'react';
import { Input, constants, Text, SelectMenu } from 'bitrus-web-ui';
import { useOnboarding } from '../../Hooks/useOnboarding';
import { ButtonSubmit } from '../ButtonSubmit';
import { ButtonReset } from '../ButtonReset';

export const FormLegalRepresentative = () => {
   const { onboardingActions, dataStore } = useOnboarding();
   const [dataLegalRepresentative, setDataLegalRepresentative] = useState({
      external_person_id: 0,
      liveness_proof: {
         file_extension: '',
         file_content: '',
      },
      signature_proof: {
         file_extension: '',
         file_content: '',
      },
   });
   const handleInputChangeLegalRepresentative = (event) => {
      setDataLegalRepresentative({
         ...dataLegalRepresentative,
         [event?.target.name]: event?.target.value,
      });
   };
   const Conver2Bse64LegalRepresentative = (e) => {
      const file = e?.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
         setDataLegalRepresentative({
            ...dataLegalRepresentative,
            [e?.target.name]: {
               file_extension: reader?.result.split(/[\s,/;]+/)[1],
               file_content: reader?.result.split(',')[1],
            },
         });
      };
      reader.readAsDataURL(file);
   };
   const handleSubmit = (e) => {
      e.preventDefault();
      let newDataLegalRepresentative = {
         ...dataLegalRepresentative,
         external_person_id: parseInt(
            dataLegalRepresentative.external_person_id
         ),
      };
      onboardingActions.CreateRepresentative(newDataLegalRepresentative);
   };
   return (
      <form
         onSubmit={handleSubmit}
         className="sm:m-16 space-y-8 divide-y divide-gray-200"
      >
         <div className="space-y-8 divide-y divide-gray-200">
            <div>
               <div>
                  <Text
                     tag={constants.tagsTypes.P}
                     styles="text-lg leading-6 font-medium text-gray-900"
                     title="Form Legal Representative"
                  />
               </div>
            </div>

            <div className="pt-8">
               <div className=" grid grid-cols-1 gap-1">
                  <div className="mt-2">
                     <SelectMenu
                        options={{
                           selectIcon: false,
                           icon: false,
                           name: 'external_person_id',
                           id: 'external_person_id',
                           title: 'External Person ID',
                           list: dataStore.dataPersons.map((data) => {
                              return {
                                 key: data.id,
                                 value: data.id,
                                 description:
                                    data.first_name + ' ' + data.second_name,
                              };
                           }),
                        }}
                        handleInputChange={handleInputChangeLegalRepresentative}
                     />
                  </div>
                  <div className="mt-2">
                     <Input
                        require={true}
                        infoInput={{
                           type: constants.inputType.FILE,
                           name: 'liveness_proof',
                           id: 'liveness_proof',
                        }}
                        label="Liveness Proof"
                        styles="block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                        handleInputChange={(e) =>
                           Conver2Bse64LegalRepresentative(e)
                        }
                     />
                  </div>
                  <div className="mt-2">
                     <Input
                        require={true}
                        infoInput={{
                           type: constants.inputType.FILE,
                           name: 'signature_proof',
                           id: 'signature_proof',
                        }}
                        label="Signature Proof"
                        styles="block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                        handleInputChange={(e) =>
                           Conver2Bse64LegalRepresentative(e)
                        }
                     />
                  </div>
               </div>
            </div>
         </div>

         <div className="pt-5">
            <ButtonSubmit />
         </div>
         <div className="pt-5">
            <ButtonReset />
         </div>
      </form>
   );
};
