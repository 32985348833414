import { createSlice } from '@reduxjs/toolkit';

export const sliceState = createSlice({
 name: 'stats',
 initialState: {
  balance: [],
 },
 reducers: {
  getStates: (state, action) => {
   state.balance = action.payload;
  },
 },
});

export const { getStates } = sliceState.actions;

export default sliceState.reducer;
