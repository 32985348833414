import { constants } from 'bitrus-web-ui';
import { countries } from '../../Utils/Constant/countries.js';
import { componentInput } from '../../Utils/Constant/inputs.js';

export const dataInputs = [
   {
      comopent: componentInput.SELECT,
      key: 1,
      options: {
         selectIcon: false,
         icon: false,
         name: 'nationality',
         id: 'nationality',
         title: 'Nationality',
         list: countries.map((country) => {
            return {
               key: country.code,
               value: country.code,
               description: country.country,
            };
         }),
      },
   },
   {
      comopent: componentInput.SELECT,
      key: 2,
      options: {
         selectIcon: false,
         icon: false,
         name: 'identification_type',
         id: 'identification_type',
         title: 'Identification type',
         list: [
            {
               key: 1,
               description: 'rfc',
            },
            {
               key: 2,
               description: 'other',
            },
         ],
      },
   },
   {
      comopent: componentInput.INPUT,
      key: 3,
      info: {
         type: constants.inputType.TEXT,
         name: 'identification_number',
         id: 'identification_number',
      },
      label: 'Identification number',
   },
   {
      comopent: componentInput.FILE,
      key: 4,
      info: {
         type: constants.inputType.FILE,
         name: 'identification_proof',
         id: 'identification_proof',
      },
      label: 'Identification proof',
   },
   {
      comopent: componentInput.INPUT,
      key: 5,
      info: {
         type: constants.inputType.TEXT,
         name: 'business_name',
         id: 'business_name',
      },
      label: 'Business name',
   },
   {
      comopent: componentInput.SELECT,
      key: 7,
      options: {
         selectIcon: false,
         icon: false,
         name: 'is_blacklisted',
         id: 'is_blacklisted',
         title: 'Is blacklisted',
         list: [
            {
               key: 1,
               description: 'true',
            },
            {
               key: 2,
               description: 'false',
            },
         ],
      },
   },
   {
      comopent: componentInput.INPUT,
      key: 6,
      info: {
         type: constants.inputType.DATE,
         name: 'establishment_date',
         id: 'establishment_date',
      },
      label: 'Establishment date',
   },
];
