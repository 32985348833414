import * as peticitions from '../helpers/ApiInterface';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import FormCompany from '../Components/FormCompany';
import { AddPerson as AddPersonComponent } from '../Components/AddPerson';
import { SetShareHolders } from '../Components/FormShareHolders';
import { SetShareholdersExternal } from '../Components/SetShareholdersExternal';
import { AddExternalCompany } from '../Components/AddExternalCompany';
import { FormLegalRepresentative } from '../Components/FormLegalRepresentative';
import { FormEmployees } from '../Components/FormEmployees';
import DateTimeHelper from '../helpers/DateTimeHelper';
import { alerts } from '../helpers/alerts';
import { setAddPerson } from '../slices/addPPersonSlice';
import { setAddExternalCompanySlice } from '../slices/addExternalCompanySlice';
import { setCreateCompanySlice } from '../slices/CreateCompanySlice';
import { typeResponse } from '../Utils/Constant/typeResponse';
import { CustomError } from './useExeption';
import { setZipCode } from '../slices/zipCodeSlice';
import { setCompanyId } from '../slices/companyIdSlice';

export const useOnboarding = () => {
   const dispatch = useDispatch();
   const companyId = useSelector((store) => store.companyId?.data);

   const dataExternalCompany = useSelector(
      (store) => store.externalCompany?.data
   );
   const dataCreateCompany = useSelector((store) => store.company?.data);
   const dataPersons = useSelector((store) => store.persons?.data);
   const dataZipCode = useSelector((store) => store.zipCode?.data);

   const [dataCompany, setDataCompany] = useState({
      company_id: 0,
      business_name: '',
      rfc: '',
      commercial_name: '',
      nationality: '',
      company_type_id: 0,
      company_actitivy_id: '',
      establishment_date: '',
      fiel: '',
      rppc: '',
      address_suburb_id: '',
      address_street: '',
      address_ext_no: '',
      address_int_no: '',
      external_clabe: '',
      financial_entity_id: '',
      fund_origin_id: 0,
      aditional_fund_provider_exists: '',
      is_blacklisted: '',
      shareholders_count: 0,
      cif_proof: {
         file_extension: '',
         file_content: '',
      },
      address_proof: {
         file_extension: '',
         file_content: '',
      },
      constitutive_act_proof: {
         file_extension: '',
         file_content: '',
      },
      legal_representative_authorization_proof: {
         file_extension: '',
         file_content: '',
      },
   });

   const [dataEmployees, setDataEmployees] = useState({
      external_person_id: 0,
      position: '',
      department: '',
   });

   const [currentStep, setCurrentStep] = useState(1);

   const steps = [
      'Create Company',
      'create Person',
      'create External Company',
      'Create Shareholders External Company',
      'Create Shareholders',
      'create employees',
      'create Legal representative',
   ];

   const Conver2Bse64CreateCompany = (e) => {
      const file = e?.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
         setDataCompany({
            ...dataCompany,
            [e?.target.name]: {
               file_extension: reader?.result.split(/[\s,/;]+/)[1],
               file_content: reader?.result.split(',')[1],
            },
         });
      };
      reader.readAsDataURL(file);
   };

   const handleInputChangeCompany = (event) => {
      setDataCompany({
         ...dataCompany,
         [event?.target.name]: event?.target.value,
      });
   };

   const handleInputChangeEmpoyess = (event) => {
      setDataEmployees({
         ...dataEmployees,
         [event?.target.name]: event?.target.value,
      });
   };

   const CreateHolders = async (data) => {
      try {
         await peticitions.createSharedHolder([data], companyId);
         alerts(typeResponse.SUCCESFUL);
      } catch (err) {
         alerts(typeResponse.ERRROR, err);
         throw new CustomError(err);
      }
   };

   const CreateCompany = async () => {
      let newDataCompany = {
         ...dataCompany,
         company_type_id: parseInt(dataCompany.company_type_id),
         fund_origin_id: parseInt(dataCompany.fund_origin_id),
         shareholders_count: parseInt(dataCompany.shareholders_count),
         aditional_fund_provider_exists: Boolean(
            dataCompany.aditional_fund_provider_exists
         ),
         is_blacklisted: Boolean(dataCompany.is_blacklisted),
         establishment_date: DateTimeHelper.UTC(dataCompany.establishment_date),
      };
      try {
         const resp = await peticitions.createCompany(
            newDataCompany,
            newDataCompany.company_id
         );
         dispatch(setCreateCompanySlice(resp));
         dispatch(setCompanyId(newDataCompany.company_id));
         alerts(typeResponse.SUCCESFUL);
      } catch (err) {
         alerts(typeResponse.ERRROR, err);
         throw new CustomError(err);
      }
   };

   const CreateEmployees = async () => {
      let newDataEmployees = {
         ...dataEmployees,
         external_person_id: parseInt(dataEmployees.external_person_id),
      };
      try {
         await peticitions.createEmployees([newDataEmployees], companyId);
         alerts(typeResponse.SUCCESFUL);
      } catch (err) {
         alerts(typeResponse.ERRROR, err);
         throw new CustomError(err);
      }
   };

   const CreateRepresentative = async (data) => {
      try {
         await peticitions.createLegalRepresentative(data, companyId);
         alerts(typeResponse.SUCCESFUL);
      } catch (err) {
         alerts(typeResponse.ERRROR, err);
         throw new CustomError(err);
      }
   };
   const zipCode = async (zip_code_id) => {
      try {
         const resp = await peticitions.zipCode(zip_code_id);
         dispatch(setZipCode(resp));
      } catch (err) {
         throw new CustomError(err);
      }
   };
   const AddPerson = async (data) => {
      try {
         const resp = await peticitions.addPeson(data, companyId);
         dispatch(setAddPerson(resp));
         alerts(typeResponse.SUCCESFUL);
      } catch (err) {
         alerts(typeResponse.ERRROR, err);
         throw new CustomError(err);
      }
   };
   const addExternalCompany = async (data) => {
      try {
         const resp = await peticitions.addExternalCompany(data, companyId);
         dispatch(setAddExternalCompanySlice(resp));
         alerts(typeResponse.SUCCESFUL);
      } catch (err) {
         alerts(typeResponse.ERRROR, err);
         throw new CustomError(err);
      }
   };

   const setShareholdersExternal = async (data, external_company_id) => {
      try {
         await peticitions.setShareholdersExternal(
            data,
            companyId,
            external_company_id
         );
         alerts(typeResponse.SUCCESFUL);
      } catch (err) {
         alerts(typeResponse.ERRROR, err);
         throw new CustomError(err);
      }
   };
   // logic form step

   /**
    * It takes a number as an argument and returns a component based on the number
    * @param step - The current step of the form.
    * @returns A function that returns a component based on the step value.
    */

   const DisplaySteps = (step) => {
      switch (step) {
         case 1:
            return <FormCompany />;
         case 2:
            return <AddPersonComponent />;
         case 3:
            return <AddExternalCompany />;
         case 4:
            return <SetShareholdersExternal />;
         case 5:
            return <SetShareHolders />;
         case 6:
            return <FormEmployees />;
         case 7:
            return <FormLegalRepresentative />;
         default:
            break;
      }
   };

   /**
    * If the direction is next, increment the current step by one, otherwise decrement it by one. If the
    * new step is greater than zero and less than or equal to the number of steps, set the current step
    * to the new step
    * @param direction - This is the direction of the button that was clicked. It can be either 'next' or
    * 'prev'.
    */
   const handleClick = (direction) => {
      let newStep = currentStep;

      direction === 'next' ? newStep++ : newStep--;
      newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);
   };

   const onboardingActions = {
      handleInputChangeCompany,
      Conver2Bse64CreateCompany,
      CreateCompany,
      addExternalCompany,
      CreateHolders,
      handleInputChangeEmpoyess,
      CreateEmployees,
      AddPerson,
      CreateRepresentative,
      setShareholdersExternal,
      currentStep,
      steps,
      DisplaySteps,
      handleClick,
   };

   const dataStore = {
      dataExternalCompany,
      dataCreateCompany,
      dataPersons,
      dataZipCode,
   };
   return {
      dataStore,
      zipCode,
      onboardingActions,
   };
};
