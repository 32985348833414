import { createSlice } from '@reduxjs/toolkit';

export const usersSlice = createSlice({
 name: 'users',
 initialState: {
  usersData: [],
 },
 reducers: {
  setUsers: (state, action) => {
   state.usersData = action.payload;
  },
 },
});

export const { setUsers } = usersSlice.actions;

export default usersSlice.reducer;
