import React from 'react';
import { NavbarApp } from 'bitrus-web-ui';
import { nameRoutes, routes } from '../Utils/Constant/routes';

const Navbar = () => {
   const argsNav = {
      navigation: [
         { name: nameRoutes.OVERVIEW, href: routes.OVERVIEW },
         { name: nameRoutes.USERS, href: routes.USERS },
         { name: nameRoutes.REPORTS, href: routes.REPORTS },
         { name: nameRoutes.PNL, href: routes.PNL },
         { name: nameRoutes.REGISTERDEPOSIT, href: routes.REGISTERDEPOSIT },
         {
            name: nameRoutes.CONCILIATIONDELETE,
            href: routes.CONCILIATIONDELETE,
         },
         { name: nameRoutes.ONBOARDIN, href: routes.ONBOARDING },
      ],
   };

   return <NavbarApp argsNav={argsNav} />;
};

export default Navbar;
