import { createSlice } from '@reduxjs/toolkit';

export const companyIdSlice = createSlice({
   name: 'companyId',
   initialState: {
      data: 0,
   },
   reducers: {
      setCompanyId: (state, action) => {
         state.data = action.payload;
      },
   },
});
export const { setCompanyId } = companyIdSlice.actions;

export default companyIdSlice.reducer;
