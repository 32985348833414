import { createSlice } from '@reduxjs/toolkit';

export const companyTypeSlice = createSlice({
   name: 'companyType',
   initialState: {
      data: [],
   },
   reducers: {
      setCompanyType: (state, action) => {
         state.data = action.payload;
      },
   },
});
export const { setCompanyType } = companyTypeSlice.actions;

export default companyTypeSlice.reducer;
