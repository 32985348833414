import { createSlice } from '@reduxjs/toolkit';

export const CreateCompanySlice = createSlice({
   name: 'company',
   initialState: {
      data: [],
   },
   reducers: {
      setCreateCompanySlice: (state, action) => {
         state.data = action.payload;
      },
   },
});
export const { setCreateCompanySlice } = CreateCompanySlice.actions;

export default CreateCompanySlice.reducer;
