import React from "react";
import { routes } from "../Utils/Constant/routes";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Stats as StatsComponet,
  Text,
  Icons,
  constants,
  typeColorsButton,
} from "bitrus-web-ui";

const Stats = ({ balance, title }) => {
  let navigate = useNavigate();

  const mapBalence = (balance) => {
    return balance.map((item) => {
      return {
        id: item.resource_id,
        name: (
          <Text
            tag={constants.tagsTypes.P}
            textType={constants.textTypes.SMALL}
            styles="ml-16 text-sm font-medium text-gray-500 truncate"
            title={item.resource_symbol}
          />
        ),
        stat: (
          <Text
            tag={constants.tagsTypes.P}
            textType={constants.textTypes.SMALL}
            styles="font-semibold text-gray-900"
            title={item.balance + " " + item.resource_symbol}
          />
        ),
        icon: Icons.Cash(),
        description: (
          <>
            <Text
              textType={constants.textTypes.SMALL}
              tag={constants.tagsTypes.P}
              styles="text-2xs font-semibold text-gray-900"
              title={`AMOUNT MXN ${item.amount_fiat}`}
            />
            <Text
              textType={constants.textTypes.SMALL}
              tag={constants.tagsTypes.P}
              styles="text-2xs font-semibold text-gray-900"
              title={`AMOUNT USD ${item.amount_usd}`}
            />
          </>
        ),
      };
    });
  };

  let data = {
    title: title,
    stats: mapBalence(balance),
  };

  return (
    <div className="my-8">
      <div className="flex">
        <Text
          styles="text-lg leading-6 font-medium text-gray-900 mx-5"
          title={title}
          tag={constants.tagsTypes.H3}
        />
        <Button
          text={`Transaction ${title}`}
          background={typeColorsButton.SECONDARY_COLOR}
          FunctionClick={() => navigate(`${routes.TX_HISTORY_PARAMS}${title}`)}
        />
      </div>
      <StatsComponet type={constants.typesStats.BRAND_ICON} data={data} />
    </div>
  );
};

export default Stats;
