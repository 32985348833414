import { createSlice } from '@reduxjs/toolkit';

export const DataDepositDeleteSlice = createSlice({
   name: 'DataDepositDelete',
   initialState: {
      data: [],
   },
   reducers: {
      setDataDepositDelete: (state, action) => {
         state.data = action.payload;
      },
   },
});
export const { setDataDepositDelete } = DataDepositDeleteSlice.actions;

export default DataDepositDeleteSlice.reducer;
