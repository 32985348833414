import React, { useState } from 'react';
import { Input, constants, Text, SelectMenu } from 'bitrus-web-ui';
import { useOnboarding } from '../../Hooks/useOnboarding';
import { ButtonSubmit } from '../ButtonSubmit';
import { ButtonReset } from '../ButtonReset';
import { typeUser } from '../../Utils/Constant/typeUser';
import { SelectMenusTypes } from '../SelectMenusTypes';

export const SetShareHolders = () => {
   const { onboardingActions } = useOnboarding();
   const [dataShareHolder, setDataShareHolder] = useState({
      shareholder_type: '',
      shareholder_id: 0,
      company_percentage: 0,
   });

   const handleInputChangeShareHolders = (event) => {
      setDataShareHolder({
         ...dataShareHolder,
         [event?.target.name]: event?.target.value,
      });
   };

   const handleSubmit = (e) => {
      e.preventDefault();
      let newDataShareHolder = {
         ...dataShareHolder,
         company_percentage: parseInt(dataShareHolder.company_percentage),
      };
      onboardingActions.CreateHolders(newDataShareHolder);
   };
   return (
      <form
         onSubmit={handleSubmit}
         className="sm:m-16 space-y-8 divide-y divide-gray-200"
      >
         <div className="space-y-8 divide-y divide-gray-200">
            <div>
               <div>
                  <Text
                     tag={constants.tagsTypes.P}
                     styles="text-lg leading-6 font-medium text-gray-900"
                     title="Form Set Shareholders"
                  />
                  <Text
                     tag={constants.tagsTypes.P}
                     styles="mt-1 text-sm text-gray-500"
                     title="This information will not be displayed publicly"
                  />
               </div>
            </div>

            <div className="pt-8">
               <div className="mt-6 grid grid-cols-1 gap-1">
                  <div className="mt-2">
                     <SelectMenu
                        options={{
                           selectIcon: false,
                           icon: false,
                           name: 'shareholder_type',
                           id: 'shareholder_type',
                           title: 'Shareholder type',
                           list: [
                              {
                                 key: 1,
                                 description: typeUser.PERSON,
                              },
                              {
                                 key: 2,
                                 description: typeUser.COMPANY,
                              },
                           ],
                        }}
                        handleInputChange={handleInputChangeShareHolders}
                     />
                  </div>
                  <div className="mt-2">
                     <SelectMenusTypes
                        type={dataShareHolder.shareholder_type}
                        handleInputChange={handleInputChangeShareHolders}
                     />
                  </div>
                  <div className="mt-2">
                     <Input
                        require={true}
                        infoInput={{
                           type: constants.inputType.NUMBER,
                           name: 'company_percentage',
                           id: 'company_percentage',
                        }}
                        label="Company Percentage "
                        styles="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        handleInputChange={handleInputChangeShareHolders}
                     />
                  </div>
               </div>
            </div>
         </div>

         <div className="pt-5">
            <ButtonSubmit />
         </div>
         <div className="pt-5">
            <ButtonReset />
         </div>
      </form>
   );
};
