import { useDispatch } from 'react-redux';
import * as peticitions from '../helpers/ApiInterface';
import { CustomError } from './useExeption';
import { setUsers } from '../slices/usersSlice';
import { useSelector } from 'react-redux';
import { setGetMeSlice } from '../slices/getMe';

const useUser = () => {
   const dispatch = useDispatch();
   const usersData = useSelector((store) => store.users?.usersData);

   const getUsers = async (skip, limit) => {
      try {
         const resp = await peticitions.getUsers(skip, limit);
         dispatch(setUsers(resp));
      } catch (err) {
         throw new CustomError(err);
      }
   };
   const getUserMe = async () => {
      try {
         const resp = await peticitions.getUserMe();
         dispatch(setGetMeSlice(resp));
      } catch (err) {
         throw new CustomError(err);
      }
   };

   const actionsUser = {
      getUsers,
      getUserMe,
   };

   return {
      actionsUser,
      usersData,
   };
};

export default useUser;
