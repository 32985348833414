import { constants } from 'bitrus-web-ui';
import { componentInput } from '../../Utils/Constant/inputs';
import { countries } from '../../Utils/Constant/countries';
export const dataInputs = [
   {
      comopent: componentInput.SELECT,
      key: 1,
      options: {
         selectIcon: false,
         icon: false,
         name: 'birth_place',
         id: 'birth_place',
         title: 'Birth place',
         list: countries.map((country) => {
            return {
               key: country.code + 'birth_plac',
               value: country.code,
               description: country.country,
            };
         }),
      },
   },
   {
      comopent: componentInput.SELECT,
      key: 2,
      options: {
         selectIcon: false,
         icon: false,
         name: 'nationality',
         id: 'nationality',
         title: 'Nationality',
         list: countries.map((country) => {
            return {
               key: country.code + 'nationality',
               value: country.code,
               description: country.country,
            };
         }),
      },
   },
   {
      comopent: componentInput.SELECT,
      key: 3,
      options: {
         selectIcon: false,
         icon: false,
         name: 'identification_type',
         id: 'identification_type',
         title: 'Identification type',
         list: [
            {
               key: 1,
               description: 'ine',
            },
            {
               key: 2,
               description: 'passport',
            },
            {
               key: 3,
               description: 'other',
            },
         ],
      },
   },
   {
      comopent: componentInput.INPUT,
      key: 4,
      info: {
         type: constants.inputType.TEXT,
         name: 'identification_number',
         id: 'identification_number',
      },
      label: 'Identification number',
   },
   {
      comopent: componentInput.FILE,
      key: 5,
      info: {
         type: constants.inputType.FILE,
         name: 'identification_proof',
         id: 'identification_proof',
      },
      label: 'Identification proof',
   },
   {
      key: 6,
      comopent: componentInput.SELECT,
      options: {
         selectIcon: false,
         icon: false,
         name: 'gender',
         id: 'gender',
         title: 'Gender',
         list: [
            {
               key: 1,
               description: 'M',
            },
            {
               key: 2,
               description: 'F',
            },
         ],
      },
   },
   {
      comopent: componentInput.INPUT,
      key: 7,
      info: {
         type: constants.inputType.DATE,
         name: 'birth_date',
         id: 'birth_date',
      },
      label: 'Birth date',
   },
   {
      comopent: componentInput.INPUT,
      key: 8,
      info: {
         type: constants.inputType.TEXT,
         name: 'first_name',
         id: 'first_name',
      },
      label: 'First name',
   },
   {
      comopent: componentInput.INPUT,
      key: 9,
      info: {
         type: constants.inputType.TEXT,
         name: 'second_name',
         id: 'second_name',
      },
      label: 'Second name',
   },
   {
      comopent: componentInput.INPUT,
      key: 10,
      info: {
         type: constants.inputType.TEXT,
         name: 'first_surname',
         id: 'first_surname',
      },
      label: 'First surname',
   },
   {
      comopent: componentInput.INPUT,
      key: 11,
      info: {
         type: constants.inputType.TEXT,
         name: 'second_surname',
         id: 'second_surname',
      },
      label: 'Second surname',
   },
   {
      comopent: componentInput.SELECT,
      key: 12,
      options: {
         selectIcon: false,
         icon: false,
         name: 'is_pep',
         id: 'is_pep',
         title: 'Is pep',
         list: [
            {
               key: 1,
               description: 'true',
            },
            {
               key: 2,
               description: 'false',
            },
         ],
      },
   },
   {
      comopent: componentInput.INPUT,
      key: 13,
      info: {
         type: constants.inputType.TEXT,
         name: 'curp',
         id: 'curp',
      },
      label: 'Curp',
   },
];
