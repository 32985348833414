import { typeResponse } from '../Utils/Constant/typeResponse';
import Swal from 'sweetalert2';
import { responses } from '../Utils/Constant/error';

export const alerts = (type, error) => {
   if (type === typeResponse.ERRROR) {
      return Swal.fire({
         icon: 'error',
         title: 'Error ' + error.code || null,
         text: responses[error.code] || 'Error iniesperado',
      });
   }
   return Swal.fire({
      icon: 'success',
      title: 'Your work has been saved',
      showConfirmButton: false,
      timer: 1500,
   });
};
