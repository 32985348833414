import React from 'react';
import { MainView } from '../../MainView';
import { useLogin } from '../../../Hooks/useLogin';
import { LoginUser } from 'bitrus-web-ui';

export const LoginView = () => {
   const { loginActions, errorMesagge, errorConditional } = useLogin();
   return (
      <MainView StyleDefault="bg-gray-50 p-8 h-screen">
         <LoginUser
            Login={loginActions.Login}
            handleInputChange={loginActions.handleInputChange}
            errorMesagge={errorMesagge}
            errorConditional={errorConditional}
            title="Sign in to your account"
            text="Bitrus Exchange"
            urlPassword="#"
            forgotPassword={false}
            labelEmail="Email"
            labelPassword="Password"
            remenber="Remember me"
         />
      </MainView>
   );
};
