import { SelectMenu } from 'bitrus-web-ui';
import React from 'react';
import { useOnboarding } from '../../Hooks/useOnboarding';
import { typeUser } from '../../Utils/Constant/typeUser';

export const SelectMenusTypes = ({ type, handleInputChange }) => {
   const { dataStore } = useOnboarding();
   if (type === typeUser.COMPANY) {
      return (
         <SelectMenu
            options={{
               selectIcon: false,
               icon: false,
               name: 'shareholder_id',
               id: 'shareholder_id',
               title: 'Shareholder',
               list: dataStore.dataExternalCompany.map((data) => {
                  return {
                     key: data.id,
                     value: data.id,
                     description: data.business_name,
                  };
               }),
            }}
            handleInputChange={handleInputChange}
         />
      );
   } else if (type === typeUser.PERSON) {
      return (
         <SelectMenu
            options={{
               selectIcon: false,
               icon: false,
               name: 'shareholder_id',
               id: 'shareholder_id',
               title: 'Shareholder',
               list: dataStore.dataPersons.map((data) => {
                  return {
                     key: data.id,
                     value: data.id,
                     description: data.first_name + ' ' + data.second_name,
                  };
               }),
            }}
            handleInputChange={handleInputChange}
         />
      );
   }
   return (
      <SelectMenu
         options={{
            selectIcon: false,
            icon: false,
            name: 'shareholder_id',
            id: 'shareholder_id',
            title: 'Shareholder',
            list: [],
         }}
      />
   );
};
