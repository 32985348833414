import { createSlice } from '@reduxjs/toolkit';

export const listDeposit = createSlice({
 name: 'DataListDeposit',
 initialState: {
  data: [],
 },
 reducers: {
  setListDeposit: (state, action) => {
   state.data = action.payload;
  },
 },
});
export const { setListDeposit } = listDeposit.actions;

export default listDeposit.reducer;
