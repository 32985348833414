import React, { useEffect, useState } from 'react';
import { UseConciliation } from '../../../Hooks/useConciliation';
import { MainView } from '../../MainView';
import {
   DeleteTable,
   Text,
   ModalConfirmation,
   constants,
   Button,
   Alert,
} from 'bitrus-web-ui';
import { CalendarIcon } from '@heroicons/react/solid';

export const ConciliationDeleteView = () => {
   const {
      conciliationActions,
      DataListDeposit,
      filterDataListDeposit,
      showModalPreview,
      setShowModalPreview,
      deleteIdTransaccion,
      DataPreview,
   } = UseConciliation();

   const [alertMessage, setAlertMessage] = useState('');
   const [alertType, setAlertType] = useState('success');
   const [bgAlert, setBgAlert] = useState('bg-red-50');

   let f = new Date();

   useEffect(() => {
      conciliationActions.getConciliationListDeposits();
   }, [showModalPreview]);

   const triggerConciliation = async () => {
      try {
         await conciliationActions.tiggerConciliation();
         setAlertMessage('successful conciliation process');
         setAlertType('success');
         setBgAlert('bg-green-50');
      } catch (err) {
         setAlertType('error');
         setAlertMessage(
            'error trying to conciliate, try again or get in touch with tech support'
         );
         setBgAlert('bg-red-50');
      }
   };

   return (
      <MainView>
         <div className="md:flex md:items-center md:justify-between">
            <div className="flex-1 min-w-0 ">
               <Text
                  title="Trigger conciliation"
                  tag={constants.tagsTypes.H2}
               />
            </div>
         </div>
         {alertMessage && (
            <div className={'mt-10 mb-20 w-96'}>
               <Alert type={alertType} title={alertMessage} color={bgAlert} />
            </div>
         )}
         <div className={'mt-10 mb-20 w-96'}>
            <p className="text-sm mb-5">
               This button triggers the process of concialiating buy orderns
               with the USD deposits on our third party exchanges and
               calculating the PNL for each order
            </p>
            <Button
               types={constants.buttonType.SUBMIT}
               text="Trigger conciliation"
               size={constants.buttonSizes.LARGE}
               FunctionClick={triggerConciliation}
            />
         </div>

         <div className="md:flex md:items-center md:justify-between">
            <div className="flex-1 min-w-0">
               <Text title="Deposit history" tag={constants.tagsTypes.H2} />
            </div>
         </div>
         {DataListDeposit?.length === 0 ? (
            <div className=" flex justify-center content-center my-36 ">
               <div className="pb-5 border-b border-gray-200">
                  <Text
                     title="You have no Deposit"
                     tag={constants.tagsTypes.H3}
                  />
               </div>
            </div>
         ) : (
            <DeleteTable
               datalistDeposit={filterDataListDeposit()}
               conciliationPreviewDeleteDeposit={
                  conciliationActions?.getPreviewDeleteDeposit
               }
            />
         )}

         <ModalConfirmation
            succes={true}
            title="Are you sure you want to Confirm of the operation?"
            viewModal={showModalPreview}
            FunctionCancel={() => setShowModalPreview(false)}
            FunctionAction={() =>
               conciliationActions?.deleteDeposits(deleteIdTransaccion)
            }
         >
            <div className="bg-white shadow overflow-hidden sm:rounded-md">
               <ul role="list" className="divide-y divide-gray-200">
                  {DataPreview.length >= 1 && (
                     <>
                        {DataPreview.map((data) => (
                           <li key={data.deposit_to_delete.id}>
                              <div className="px-4 py-4 sm:px-6">
                                 <div className="flex items-center justify-between">
                                    <p className="text-sm font-medium text-red-600 truncate">
                                       Deposit To Delete
                                    </p>
                                    <div className="ml-2 flex-shrink-0 flex">
                                       {data.deposit_to_delete.is_consumed ? (
                                          <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                             Is Consumed
                                          </p>
                                       ) : (
                                          <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                                             Is Consumed
                                          </p>
                                       )}
                                    </div>
                                 </div>
                                 <div className="mt-2 sm:flex sm:justify-between">
                                    <div className="sm:flex">
                                       <p className="flex items-center text-sm leading-5 px-2 font-semibold rounded-full bg-red-100 text-red-800">
                                          USD Amount{' '}
                                          {data.deposit_to_delete.usd_amount}
                                       </p>
                                       <p className="flex items-center text-sm leading-5 px-2 font-semibold rounded-full bg-red-100 text-red-800 ml-2">
                                          Fiat Mount{' '}
                                          {data.deposit_to_delete.fiat_amount}
                                       </p>
                                    </div>
                                    <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                                       <CalendarIcon
                                          className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                          aria-hidden="true"
                                       />
                                       <p>
                                          {data.deposit_to_delete.consumed_at
                                             ? f.toLocaleDateString(
                                                  'es-MX',
                                                  data.deposit_to_delete
                                                     .consumed_at
                                               )
                                             : 'N/A'}
                                       </p>
                                    </div>
                                 </div>
                              </div>
                           </li>
                        ))}
                        {DataPreview[0].deposits_to_conciliate.map((data) => (
                           <li key={data.id}>
                              <div className="px-4 py-4 sm:px-6">
                                 <div className="flex items-center justify-between">
                                    <p className="text-sm font-medium text-green-600 truncate">
                                       Deposits To Conciliate
                                    </p>
                                    <div className="ml-2 flex-shrink-0 flex">
                                       <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                          Conciliate
                                       </p>
                                    </div>
                                 </div>
                                 <div className="mt-2 sm:flex sm:justify-between">
                                    <div className="sm:flex">
                                       <p className="flex items-center text-sm leading-5 px-2 font-semibold rounded-full bg-green-100 text-green-800">
                                          USD Amount {data.usd_amount}
                                       </p>
                                       <p className="flex items-center text-sm leading-5 px-2 font-semibold rounded-full bg-green-100 text-green-800 ml-2">
                                          Fiat Mount {data.fiat_amount}
                                       </p>
                                    </div>
                                    <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                                       <CalendarIcon
                                          className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                          aria-hidden="true"
                                       />
                                       <p>
                                          {data.consumed_at
                                             ? f.toLocaleDateString(
                                                  'es-MX',
                                                  data.consumed_at
                                               )
                                             : 'N/A'}
                                       </p>
                                    </div>
                                 </div>
                              </div>
                           </li>
                        ))}
                        {DataPreview[0].buy_order_to_conciliate.length >= 1 && (
                           <li>
                              <div className="px-4 py-4 sm:px-6">
                                 <div className="flex items-center justify-between">
                                    <p className="text-sm font-medium text-green-600 truncate">
                                       Buy Order To Conciliate
                                    </p>
                                    <div className="ml-2 flex-shrink-0 flex">
                                       <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                          Conciliate
                                       </p>
                                    </div>
                                 </div>
                                 {DataPreview[0].buy_order_to_conciliate.map(
                                    (data) => (
                                       <div className="mt-2 sm:flex sm:justify-between">
                                          <div className="sm:flex justify-between">
                                             <p className="flex items-center text-sm leading-5 px-2 font-semibold rounded-full bg-green-100 text-green-800">
                                                Amount {data.asset_amount}
                                             </p>
                                             <p className="flex items-center text-sm leading-5 px-2 font-semibold rounded-full bg-green-100 text-green-800 ml-6 ">
                                                {data.buy_order_status}
                                             </p>
                                          </div>
                                          <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                                             <CalendarIcon
                                                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                                aria-hidden="true"
                                             />
                                             <p>
                                                {data.created_at
                                                   ? f.toLocaleDateString(
                                                        'es-MX',
                                                        data.created_at
                                                     )
                                                   : 'N/A'}
                                             </p>
                                          </div>
                                       </div>
                                    )
                                 )}
                              </div>
                           </li>
                        )}
                     </>
                  )}
               </ul>
            </div>
         </ModalConfirmation>
      </MainView>
   );
};
