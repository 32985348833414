import { createSlice } from '@reduxjs/toolkit';

export const addExternalCompanySlice = createSlice({
   name: 'externalCompany',
   initialState: {
      data: [],
   },
   reducers: {
      setAddExternalCompanySlice: (state, action) => {
         state.data = [...state.data, action.payload];
      },
   },
});
export const { setAddExternalCompanySlice } = addExternalCompanySlice.actions;

export default addExternalCompanySlice.reducer;
