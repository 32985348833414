import React, { useEffect } from 'react';
import Stats from '../../../Components/Stats';
import DateTimeHelper from '../../../helpers/DateTimeHelper';
import useDashboard from '../../../Hooks/useDashboard';
import { constants, Text } from 'bitrus-web-ui';
import { nameRoutes } from '../../../Utils/Constant/routes';
import useUser from '../../../Hooks/useUser';

const Overview = () => {
   const { actionsDashboard, dataDashboard } = useDashboard();
   const { actionsUser } = useUser();
   let lastMonth = DateTimeHelper.ToFullDate(new Date());
   let day = DateTimeHelper.ToRequestDateString(new Date());

   useEffect(() => {
      actionsDashboard.getStats();
      actionsDashboard.financialEntity();
      actionsDashboard.companyType();
      actionsDashboard.fundOrigin();
      actionsDashboard.companyActivity();
      actionsUser.getUserMe();
      actionsDashboard.getTX(lastMonth, day);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <div className="w-11/12 m-auto">
         <Text
            tag={constants.tagsTypes.H2}
            textType={constants.textTypes.HEADING2}
            title={nameRoutes.OVERVIEW}
            styles="mt-8"
         />
         {dataDashboard.stats &&
            dataDashboard.stats.map((balance) => (
               <Stats
                  key={balance.provider_id}
                  balance={balance.balances}
                  title={balance.provider_name}
               />
            ))}
      </div>
   );
};

export default Overview;
