import React from 'react';
import { Button, constants, typeColorsButton } from 'bitrus-web-ui';

export const StepControl = ({ handleClick, currentStep, steps }) => {
   return (
      <div className="container flex justify-around mt-4 mb-8  ">
         {/* back button  */}
         <Button
            text="BACK"
            background={typeColorsButton.WHITE_COLOR}
            size={constants.buttonSizes.ULTRA_LARGE}
            FunctionClick={() => handleClick()}
         />

         {/* next button  */}
         <Button
            size={constants.buttonSizes.ULTRA_LARGE}
            text="Next"
            background={typeColorsButton.PRIMARY_COLOR}
            FunctionClick={() => handleClick('next')}
         />
      </div>
   );
};
