import React from 'react';
import {
   Text,
   Input,
   Button,
   constants,
   typeColorsButton,
} from 'bitrus-web-ui';
import CurrencyInput from 'react-currency-input-field';

export const Deposit = ({
   setOpen,
   handleInputChangeDeposit,
   disable,
   dataDeposit,
}) => {
   return (
      <form className="space-y-8 divide-y divide-gray-200 sm:my-10 sm:mx-10 mx-24 my-24">
         <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
               <div className="space-y-6 sm:space-y-5">
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                     <Text
                        title="Amount of MXN exchanged for dollars"
                        tag={constants.tagsTypes.LABEL}
                        styles="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                     />
                     <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <CurrencyInput
                           id="fiat_amount"
                           name="fiat_amount"
                           placeholder="Please enter a number"
                           defaultValue={0}
                           decimalsLimit={2}
                           onValueChange={(value, name) =>
                              handleInputChangeDeposit(value, name)
                           }
                        />
                     </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                     <Text
                        title="Amounts of USD received at the exchange"
                        tag={constants.tagsTypes.LABEL}
                        styles="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                     />
                     <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <CurrencyInput
                           id="usd_amount"
                           name="usd_amount"
                           placeholder="Please enter a number"
                           defaultValue={0}
                           decimalsLimit={2}
                           onValueChange={(value, name) =>
                              handleInputChangeDeposit(value, name)
                           }
                        />
                     </div>
                  </div>
               </div>
               <div className="space-y-6 sm:space-y-5">
                  {dataDeposit.usd_amount && (
                     <div className="flex">
                        <Text
                           type={constants.textTypes.LARGE}
                           tag={constants.tagsTypes.P}
                           title={` Implicit exchange rate:`}
                        />
                        <Text
                           type={constants.textTypes.HEADING5}
                           tag={constants.tagsTypes.H5}
                           styles="ml-5"
                           title={
                              ' 1 USD = ' +
                              `${Number.parseFloat(
                                 Number.parseFloat(dataDeposit.fiat_amount) /
                                    Number.parseFloat(dataDeposit.usd_amount)
                              ).toFixed(2)} MXN`
                           }
                           bold={false}
                        />
                     </div>
                  )}
               </div>
            </div>
         </div>

         <div className="pt-5">
            <div className="flex justify-end">
               <Button
                  FunctionClick={() => setOpen(true)}
                  background={
                     disable
                        ? typeColorsButton.WHITE_COLOR
                        : typeColorsButton.PRIMARY_COLOR
                  }
                  text="Deposit"
                  type={constants.buttonType.SUBMIT}
                  size={constants.buttonSizes.MEDIUM}
                  disableButton={disable}
               />
            </div>
         </div>
      </form>
   );
};
