import { useState } from 'react';
import { accesToken } from '../helpers/ApiInterface';
import { responses } from '../Utils/Constant/error';
import { USER_LOGIN } from '../Utils/Constant/storage';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { loginAction } from '../slices/loginSlice';

export const useLogin = () => {
   const dispatch = useDispatch();
   const [isLoggedIn, setisLoggedIn] = useState(false);
   const [errorConditional, setErrorConditional] = useState(false);
   const userToken = useSelector((store) => store.user?.token);
   const [errorMesagge, setErrorMesagge] = useState('');
   const [dataUser, setDataUser] = useState({
      email: '',
      password: '',
   });
   const setTrue = () => {
      setisLoggedIn(true);
   };
   const setFalse = () => {
      setisLoggedIn(false);
   };
   const handleInputChange = (event) => {
      setDataUser({
         ...dataUser,
         [event.target.name]: event.target.value,
      });
   };

   const Login = async (e) => {
      e.preventDefault();
      try {
         const resp = await accesToken(dataUser);
         dispatch(loginAction(resp));
         localStorage.setItem(
            USER_LOGIN,
            JSON.stringify({
               ACCESS_TOKEN: resp.access_token,
               REFRESH_TOKEN: resp.refresh_token,
            })
         );
         setTrue();
      } catch (err) {
         setErrorMesagge(responses[err?.code]);
         setErrorConditional(true);
         setTimeout(() => {
            setErrorConditional(false);
         }, 9000);
      }
   };

   const loginActions = {
      handleInputChange,
      Login,
   };

   return {
      isLoggedIn,
      setTrue,
      setFalse,
      errorMesagge,
      errorConditional,
      loginActions,
      userToken,
   };
};
