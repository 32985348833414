import React from 'react';
import { MainView } from '../../MainView';
import { useOnboarding } from '../../../Hooks/useOnboarding';
import { StepComponent } from '../../../Components/stepComponent';
import { StepControl } from '../../../Components/stepComponent/StepControl';
import { ViewContext } from '../../../Components/stepComponent/ViewContext';
import { Button, constants, typeColorsButton } from 'bitrus-web-ui';

const Onboarding = () => {
   const { onboardingActions } = useOnboarding();

   return (
      <MainView>
         <div className="m-12 mx-auto rounded-2xl bg-white pb-2 shadow-xl md:w-3/4">
            <div className="container horizontal mt-5 mx-1 px-5">
               <StepComponent
                  steps={onboardingActions.steps}
                  currentStep={onboardingActions.currentStep}
               />
               <div className="my-10 p-5 ">
                  <ViewContext>
                     {onboardingActions.DisplaySteps(
                        onboardingActions.currentStep
                     )}
                  </ViewContext>
               </div>
            </div>
            {onboardingActions.currentStep !==
               onboardingActions.steps.length && (
               <StepControl
                  handleClick={onboardingActions.handleClick}
                  currentStep={onboardingActions.currentStep}
                  steps={onboardingActions.steps}
               />
            )}
            {onboardingActions.currentStep ===
               onboardingActions.steps.length && (
               <div className="container flex justify-start mt-4 mb-8 ml-20 ">
                  <Button
                     text="BACK"
                     background={typeColorsButton.WHITE_COLOR}
                     size={constants.buttonSizes.ULTRA_LARGE}
                     FunctionClick={onboardingActions.handleClick}
                  />
               </div>
            )}
         </div>
      </MainView>
   );
};

export default Onboarding;
