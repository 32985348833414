import { createSlice } from '@reduxjs/toolkit';

export const addPPersonSlice = createSlice({
   name: 'persons',
   initialState: {
      data: [],
   },
   reducers: {
      setAddPerson: (state, action) => {
         state.data = [...state.data, action.payload];
      },
   },
});
export const { setAddPerson } = addPPersonSlice.actions;

export default addPPersonSlice.reducer;
