import { Button, constants, typeColorsButton } from 'bitrus-web-ui';
import React from 'react';

export const ButtonReset = () => {
   return (
      <Button
         background={typeColorsButton.WHITE_COLOR}
         types={constants.buttonType.RESET}
         size={constants.buttonSizes.FULL}
         text="Add another"
      />
   );
};
