import { createSlice } from '@reduxjs/toolkit';

export const getMeSlice = createSlice({
   name: 'getMe',
   initialState: {
      data: [],
   },
   reducers: {
      setGetMeSlice: (state, action) => {
         state.data = [...state.data, action.payload];
      },
   },
});
export const { setGetMeSlice } = getMeSlice.actions;

export default getMeSlice.reducer;
