import { Button, constants } from 'bitrus-web-ui';
import React from 'react';

export const ButtonSubmit = () => {
   return (
      <Button
         types={constants.buttonType.SUBMIT}
         size={constants.buttonSizes.FULL}
         text="Send"
      />
   );
};
