import { createSlice } from '@reduxjs/toolkit';

export const txSlice = createSlice({
 name: 'tx',
 initialState: {
  txData: [],
 },
 reducers: {
  setTx: (state, action) => {
   state.txData = action.payload;
  },
 },
});

export const { setTx } = txSlice.actions;

export default txSlice.reducer;
