import { useEffect, useState } from 'react';
import { DepositMarketMaker } from '../helpers/ApiInterface';
import { CustomError } from './useExeption';

export const useDeposit = () => {
   const [open, setOpen] = useState(false);
   const [disable, setDisable] = useState(true);
   const [dataDeposit, setDataDeposit] = useState({
      fiat_id: '1',
      fiat_amount: '',
      usd_amount: '',
      liquidity_provider_id: '4',
   });

   useEffect(() => {
      if (
         dataDeposit?.fiat_amount?.length > 0 &&
         dataDeposit?.usd_amount?.length > 0
      ) {
         setDisable(false);
      } else {
         setDisable(true);
      }
   }, [dataDeposit]);

   const handleInputChangeDeposit = (value, name) => {
      setDataDeposit({
         ...dataDeposit,
         [name]: value,
      });
   };

   const DepositMarket = async (e) => {
      try {
         await DepositMarketMaker(dataDeposit);
         // setAlertMessage('Deposit successfuly registered');
         // setAlertColor('bg-green-50');
         // setAlertType('success');
         setOpen(false);
      } catch (err) {
         throw new CustomError(err);
      }
   };

   const depositActions = {
      handleInputChangeDeposit,
      DepositMarket,
   };

   return {
      depositActions,
      open,
      setOpen,
      dataDeposit,
      disable,
   };
};
