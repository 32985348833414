import { createSlice } from '@reduxjs/toolkit';

export const loginSlice = createSlice({
 name: 'user',
 initialState: {
  token: {},
 },
 reducers: {
  loginAction: (state, action) => {
   state.token = action.payload;
  },
 },
});

export const { loginAction } = loginSlice.actions;

export default loginSlice.reducer;
