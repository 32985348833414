import React from 'react';
import { constants, Text } from 'bitrus-web-ui';
import { nameRoutes } from '../../../Utils/Constant/routes';

const Reports = () => {
 return (
  <div>
   <Text
    tag={constants.tagsTypes.H2}
    textType={constants.textTypes.HEADING2}
    title={nameRoutes.REPORTS}
    styles="text-center mt-20"
   />
  </div>
 );
};

export default Reports;
