/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import DashboardRoutes from './DashboardRoutes';
import { PublicRoute } from './PublicRoute';
import { PrivateRoute } from './PrivateRoute';
import { LoginView } from '../Pages/publics/Login/index';
import { routes } from '../Utils/Constant/routes';
import { useLogin } from '../Hooks/useLogin';
import { USER_LOGIN } from '../Utils/Constant/storage';
import { validateToken } from '../helpers/validateToken';
const AppRouter = () => {
   const { isLoggedIn, setTrue } = useLogin();
   let accessToken = localStorage.getItem(USER_LOGIN)
      ? JSON.parse(localStorage.getItem(USER_LOGIN))
      : null;

   useEffect(() => {
      if (!validateToken(accessToken?.ACCESS_TOKEN)) {
         setTrue();
      }
   }, [accessToken]);

   return (
      <BrowserRouter>
         <Routes>
            <Route
               path={routes.LOGIN}
               element={
                  <PublicRoute isAuthenticated={isLoggedIn}>
                     <LoginView />
                  </PublicRoute>
               }
            />

            <Route
               path={routes.REDIRECT}
               element={
                  <PrivateRoute isAuthenticated={isLoggedIn}>
                     <DashboardRoutes />
                  </PrivateRoute>
               }
            />
         </Routes>
      </BrowserRouter>
   );
};

export default AppRouter;
