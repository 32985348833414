import { createSlice } from '@reduxjs/toolkit';

export const DataPreviewSlice = createSlice({
   name: 'DataDeletePreview',
   initialState: {
      data: [],
   },
   reducers: {
      setDataDeletePreview: (state, action) => {
         state.data = action.payload;
      },
   },
});
export const { setDataDeletePreview } = DataPreviewSlice.actions;

export default DataPreviewSlice.reducer;
