import { createSlice } from '@reduxjs/toolkit';

export const DepositDataSlice = createSlice({
   name: 'DepositData',
   initialState: {
      data: [],
   },
   reducers: {
      setDepositData: (state, action) => {
         state.data = action.payload;
      },
   },
});
export const { setDepositData } = DepositDataSlice.actions;

export default DepositDataSlice.reducer;
