import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TableSimple, Text, constants } from 'bitrus-web-ui';
import useDashboard from '../../../Hooks/useDashboard';

const TxHistory = () => {
   const params = useParams();
   const { type } = params;
   const [data, setData] = useState([]);
   const { dataDashboard } = useDashboard();

   const FilterData = (data) => {
      let dataComplete = data.filter((d) => d.provider_name === type);
      setData(dataComplete);
   };

   useEffect(() => {
      FilterData(dataDashboard.tx);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <div>
         {data?.length ? (
            <TableSimple
               dataTable={data}
               fullWidth={false}
               stripedRows={false}
            />
         ) : (
            <Text
               tag={constants.tagsTypes.H2}
               textType={constants.textTypes.HEADING2}
               title="No tienes en los ultimos 30 días"
               styles="text-center mt-20"
            />
         )}
      </div>
   );
};

export default TxHistory;
