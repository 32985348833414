import React from 'react';
import { Input, constants, Text, SelectMenu } from 'bitrus-web-ui';
import { useOnboarding } from '../../Hooks/useOnboarding';
import { ButtonSubmit } from '../ButtonSubmit';
import { ButtonReset } from '../ButtonReset';

export const FormEmployees = () => {
   const { onboardingActions, dataStore } = useOnboarding();

   const handleSubmit = (e) => {
      e.preventDefault();
      onboardingActions.CreateEmployees();
   };
   return (
      <form
         onSubmit={handleSubmit}
         className="sm:m-16 space-y-8 divide-y divide-gray-200"
      >
         <div className="space-y-8 divide-y divide-gray-200">
            <div>
               <div>
                  <Text
                     tag={constants.tagsTypes.P}
                     styles="text-lg leading-6 font-medium text-gray-900"
                     title="Form Set employees"
                  />
               </div>
            </div>

            <div className="pt-8">
               <div className="mt-6 grid grid-cols-1 gap-1">
                  <div className="mt-2">
                     <SelectMenu
                        options={{
                           selectIcon: false,
                           icon: false,
                           name: 'external_person_id',
                           id: 'external_person_id',
                           title: 'External Person ID',
                           list: dataStore.dataPersons.map((data) => {
                              return {
                                 key: data.id,
                                 value: data.id,
                                 description:
                                    data.first_name + ' ' + data.second_name,
                              };
                           }),
                        }}
                        handleInputChange={
                           onboardingActions?.handleInputChangeEmpoyess
                        }
                     />
                  </div>
                  <div className="mt-2">
                     <SelectMenu
                        options={{
                           selectIcon: false,
                           icon: false,
                           name: 'position',
                           id: 'position',
                           title: 'Position',
                           list: [
                              {
                                 key: 1,
                                 value: 'executive_director',
                                 description: 'Executive director',
                              },
                              {
                                 key: 2,
                                 value: 'second_degree_manager',
                                 description: 'Second degree manager',
                              },
                              {
                                 key: 3,
                                 value: 'counselor',
                                 description: 'Counselor',
                              },
                           ],
                        }}
                        handleInputChange={
                           onboardingActions?.handleInputChangeEmpoyess
                        }
                     />
                  </div>
                  <div className="mt-2">
                     <Input
                        require={true}
                        infoInput={{
                           type: constants.inputType.TEXT,
                           name: 'department',
                           id: 'department',
                        }}
                        label="Department"
                        styles="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        handleInputChange={
                           onboardingActions?.handleInputChangeEmpoyess
                        }
                     />
                  </div>
               </div>
            </div>
         </div>

         <div className="pt-5">
            <ButtonSubmit />
         </div>
         <div className="pt-5">
            <ButtonReset />
         </div>
      </form>
   );
};
