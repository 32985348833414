import * as peticitions from '../helpers/ApiInterface';
import { useDispatch } from 'react-redux';
import { getStates } from '../slices/statsSlice';
import { setTx } from '../slices/txSlice';
import { CustomError } from './useExeption';
import { useSelector } from 'react-redux';
import { setCompanyType } from '../slices/companyTypeSlice';
import { setCompanyActivity } from '../slices/companyActivitySlice';
import { setFundOrigin } from '../slices/fundOriginSlice';
import { setfinancialEntity } from '../slices/financialEntitySlice';

const useDashboard = () => {
   const dispatch = useDispatch();
   const stats = useSelector((store) => store.stats?.balance);
   const tx = useSelector((store) => store.tx?.txData);
   const companyTypeDate = useSelector((store) => store.companyType?.data);
   const companyActivityDate = useSelector(
      (store) => store.companyActivity?.data
   );
   const financialEntityDate = useSelector(
      (store) => store.financialEntity?.data
   );
   const fundOriginDate = useSelector((store) => store.fundOrigin?.data);

   const getStats = async () => {
      try {
         const resp = await peticitions.balance();
         dispatch(getStates(resp));
      } catch (err) {
         throw new CustomError(err);
      }
   };

   const getTX = async (start, end) => {
      try {
         const resp = await peticitions.adminTx(start, end);
         dispatch(setTx(resp));
      } catch (err) {
         throw new CustomError(err);
      }
   };
   const companyType = async () => {
      try {
         const resp = await peticitions.companyType();

         dispatch(setCompanyType(resp));
      } catch (err) {
         throw new CustomError(err);
      }
   };
   const companyActivity = async () => {
      try {
         const resp = await peticitions.companyActivity();

         dispatch(setCompanyActivity(resp));
      } catch (err) {
         throw new CustomError(err);
      }
   };
   const financialEntity = async () => {
      try {
         const resp = await peticitions.financialEntity();
         dispatch(setfinancialEntity(resp));
      } catch (err) {
         throw new CustomError(err);
      }
   };
   const fundOrigin = async () => {
      try {
         const resp = await peticitions.fundOrigin();

         dispatch(setFundOrigin(resp));
      } catch (err) {
         throw new CustomError(err);
      }
   };

   const actionsDashboard = {
      getStats,
      getTX,
      financialEntity,
      companyType,
      fundOrigin,
      companyActivity,
   };
   const dataDashboard = {
      stats,
      tx,
      companyTypeDate,
      companyActivityDate,
      financialEntityDate,
      fundOriginDate,
   };
   return {
      actionsDashboard,
      dataDashboard,
   };
};

export default useDashboard;
