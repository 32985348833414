/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Input, constants, Text, SelectMenu } from 'bitrus-web-ui';
import { useOnboarding } from '../../Hooks/useOnboarding';
import { countries } from '../../Utils/Constant/countries';
import { ButtonSubmit } from '../ButtonSubmit';
import useDashboard from '../../Hooks/useDashboard';

export default function FormCompany() {
   const { onboardingActions, zipCode, dataStore } = useOnboarding();
   const [zipCodeNumber, setzipCodeNumber] = useState({
      zip_code: 0,
   });
   const handleInputChange = (event) => {
      setzipCodeNumber({
         ...zipCodeNumber,
         [event?.target.name]: event?.target.value,
      });
   };
   useEffect(() => {
      if (zipCodeNumber.zip_code.length === 5) {
         zipCode(zipCodeNumber.zip_code);
      }
   }, [zipCodeNumber.zip_code]);
   const { dataDashboard } = useDashboard();
   const handleSubmit = (e) => {
      e.preventDefault();
      onboardingActions.CreateCompany();
   };
   return (
      <form
         onSubmit={handleSubmit}
         className="sm:m-16 space-y-8 divide-y divide-gray-200"
      >
         <div className="space-y-8 divide-y divide-gray-200">
            <div>
               <div>
                  <Text
                     tag={constants.tagsTypes.P}
                     styles="text-lg leading-6 font-medium text-gray-900"
                     title="Form Register Company"
                  />
               </div>
            </div>

            <div className="pt-8">
               <div>
                  <Text
                     tag={constants.tagsTypes.P}
                     styles="text-lg leading-6 font-medium text-gray-900"
                     title="Company ID"
                  />
                  <div className="mb-6 mt-6 grid  grid-cols-1 gap-1">
                     <div className="mt-1">
                        <Input
                           require={true}
                           infoInput={{
                              type: constants.inputType.NUMBER,
                              name: 'company_id',
                              id: 'company_id',
                           }}
                           label="Company ID"
                           styles="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                           handleInputChange={
                              onboardingActions?.handleInputChangeCompany
                           }
                        />
                     </div>
                  </div>
               </div>

               <div>
                  <Text
                     tag={constants.tagsTypes.P}
                     styles="text-lg leading-6 font-medium text-gray-900"
                     title="Company Information"
                  />
               </div>
               <div className="mt-6 grid sm:grid-cols-3 sm:gap-3 md:grid-cols-3 md:gap-3">
                  <div className="mt-1">
                     <Input
                        require={true}
                        infoInput={{
                           type: constants.inputType.TEXT,
                           name: 'business_name',
                           id: 'business_name',
                        }}
                        label="Business Name"
                        styles="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        handleInputChange={
                           onboardingActions?.handleInputChangeCompany
                        }
                     />
                  </div>
                  <div className="mt-1">
                     <Input
                        require={true}
                        infoInput={{
                           type: constants.inputType.TEXT,
                           name: 'rfc',
                           id: 'rfc',
                        }}
                        label="RFC"
                        styles="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        handleInputChange={
                           onboardingActions?.handleInputChangeCompany
                        }
                     />
                  </div>
                  <div className="mt-1">
                     <Input
                        require={true}
                        infoInput={{
                           type: constants.inputType.TEXT,
                           name: 'commercial_name',
                           id: 'commercial_name',
                        }}
                        label="Commercial Name"
                        styles="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        handleInputChange={
                           onboardingActions?.handleInputChangeCompany
                        }
                     />
                  </div>
                  <div className="mt-1">
                     <SelectMenu
                        options={{
                           selectIcon: false,
                           icon: false,
                           name: 'company_actitivy_id',
                           id: 'company_actitivy_id',
                           title: 'Company Actitivy',
                           list: dataDashboard.companyActivityDate.map(
                              (data) => {
                                 return {
                                    key: data.id,
                                    value: data.id,
                                    description: data.name,
                                 };
                              }
                           ),
                        }}
                        handleInputChange={
                           onboardingActions?.handleInputChangeCompany
                        }
                     />
                  </div>
                  <div className="mt-1">
                     <SelectMenu
                        options={{
                           selectIcon: false,
                           icon: false,
                           name: 'nationality',
                           id: 'nationality',
                           title: 'Nationality',
                           list: countries.map((country) => {
                              return {
                                 key: country.code,
                                 value: country.code,
                                 description: country.country,
                              };
                           }),
                        }}
                        handleInputChange={
                           onboardingActions?.handleInputChangeCompany
                        }
                     />
                  </div>

                  <div className="mt-1">
                     <SelectMenu
                        options={{
                           selectIcon: false,
                           icon: false,
                           name: 'company_type_id',
                           id: 'company_type_id',
                           title: 'Company type',
                           list: dataDashboard.companyTypeDate.map((data) => {
                              return {
                                 key: data.id,
                                 value: data.id,
                                 description: data.name,
                              };
                           }),
                        }}
                        handleInputChange={
                           onboardingActions?.handleInputChangeCompany
                        }
                     />
                  </div>
                  <div className="mt-1">
                     <Input
                        require={true}
                        infoInput={{
                           type: constants.inputType.DATE,
                           name: 'establishment_date',
                           id: 'establishment_date',
                        }}
                        label="Establishment Date"
                        styles="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        handleInputChange={
                           onboardingActions?.handleInputChangeCompany
                        }
                     />
                  </div>
                  <div className="mt-1">
                     <Input
                        require={true}
                        infoInput={{
                           type: constants.inputType.TEXT,
                           name: 'fiel',
                           id: 'fiel',
                        }}
                        label="Fiel"
                        styles="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        handleInputChange={
                           onboardingActions?.handleInputChangeCompany
                        }
                     />
                  </div>
                  <div className="mt-1">
                     <Input
                        require={true}
                        infoInput={{
                           type: constants.inputType.TEXT,
                           name: 'rppc',
                           id: 'rppc',
                        }}
                        label="RPPC"
                        styles="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        handleInputChange={
                           onboardingActions?.handleInputChangeCompany
                        }
                     />
                  </div>
               </div>
               <div className="mt-8">
                  <Text
                     tag={constants.tagsTypes.P}
                     styles="text-lg leading-6 font-medium text-gray-900"
                     title="Address Information"
                  />
               </div>
               <div className="mt-6 grid sm:grid-cols-4 sm:gap-4 md:grid-cols-4 md:gap-4">
                  <div className="mt-1">
                     <Input
                        require={true}
                        infoInput={{
                           type: constants.inputType.NUMBER,
                           name: 'zip_code',
                           id: 'zip_code',
                        }}
                        label="Zip code"
                        styles="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        handleInputChange={handleInputChange}
                     />
                  </div>
                  <div className="mt-1">
                     <SelectMenu
                        options={{
                           selectIcon: false,
                           icon: false,
                           name: 'address_suburb_id',
                           id: 'address_suburb_id',
                           title: 'Address Suburb',
                           list: dataStore.dataZipCode.map((data) => {
                              return {
                                 key: data.id,
                                 value: data.id,
                                 description:
                                    data.name + ' ' + data.municipality.name,
                              };
                           }),
                        }}
                        handleInputChange={
                           onboardingActions?.handleInputChangeCompany
                        }
                     />
                  </div>
                  <div className="mt-1">
                     <Input
                        require={true}
                        infoInput={{
                           type: constants.inputType.TEXT,
                           name: 'address_street',
                           id: 'address_street',
                        }}
                        label="Address Street"
                        styles="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        handleInputChange={
                           onboardingActions?.handleInputChangeCompany
                        }
                     />
                  </div>
                  <div className="mt-1">
                     <Input
                        require={true}
                        infoInput={{
                           type: constants.inputType.TEXT,
                           name: 'address_ext_no',
                           id: 'address_ext_no',
                        }}
                        label="Address Ext No"
                        styles="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        handleInputChange={
                           onboardingActions?.handleInputChangeCompany
                        }
                     />
                  </div>
                  <div className="mt-1">
                     <Input
                        require={true}
                        infoInput={{
                           type: constants.inputType.TEXT,
                           name: 'address_int_no',
                           id: 'address_int_no',
                        }}
                        label="Address Int No"
                        styles="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        handleInputChange={
                           onboardingActions?.handleInputChangeCompany
                        }
                     />
                  </div>
               </div>
               <div className="mt-8">
                  <Text
                     tag={constants.tagsTypes.P}
                     styles="text-lg leading-6 font-medium text-gray-900"
                     title="Others Information"
                  />
               </div>
               <div className="mt-6 grid sm:grid-cols-2 sm:gap-2 md:grid-cols-4 md:gap-4">
                  <div className="mt-1">
                     <Input
                        require={true}
                        infoInput={{
                           type: constants.inputType.TEXT,
                           name: 'external_clabe',
                           id: 'external_clabe',
                        }}
                        label="External Clabe"
                        styles="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        handleInputChange={
                           onboardingActions?.handleInputChangeCompany
                        }
                     />
                  </div>
                  <div className="mt-1">
                     <Input
                        require={true}
                        infoInput={{
                           type: constants.inputType.TEXT,
                           name: 'address_street',
                           id: 'address_street',
                        }}
                        label="Address Street"
                        styles="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        handleInputChange={
                           onboardingActions?.handleInputChangeCompany
                        }
                     />
                  </div>
                  <div className="mt-1">
                     <SelectMenu
                        options={{
                           selectIcon: false,
                           icon: false,
                           name: 'financial_entity_id',
                           id: 'financial_entity_id',
                           title: 'Financial Entity',
                           list: dataDashboard.financialEntityDate.map(
                              (data) => {
                                 return {
                                    key: data.id,
                                    value: data.id,
                                    description: data.name,
                                 };
                              }
                           ),
                        }}
                        handleInputChange={
                           onboardingActions?.handleInputChangeCompany
                        }
                     />
                  </div>
                  <div className="mt-1">
                     <SelectMenu
                        options={{
                           selectIcon: false,
                           icon: false,
                           name: 'fund_origin_id',
                           id: 'fund_origin_id',
                           title: 'Fund Origin',
                           list: dataDashboard.fundOriginDate.map((data) => {
                              return {
                                 key: data.id,
                                 value: data.id,
                                 description: data.name,
                              };
                           }),
                        }}
                        handleInputChange={
                           onboardingActions?.handleInputChangeCompany
                        }
                     />
                  </div>
                  <div className="mt-1">
                     <SelectMenu
                        options={{
                           selectIcon: false,
                           icon: false,
                           name: 'aditional_fund_provider_exists',
                           id: 'aditional_fund_provider_exists',
                           title: 'Aditional fund provider exists',
                           list: [
                              {
                                 key: 1,
                                 description: 'true',
                              },
                              {
                                 key: 2,
                                 description: 'false',
                              },
                           ],
                        }}
                        handleInputChange={
                           onboardingActions?.handleInputChangeCompany
                        }
                     />
                  </div>
                  <div className="mt-1">
                     <SelectMenu
                        options={{
                           selectIcon: false,
                           icon: false,
                           name: 'is_blacklisted',
                           id: 'is_blacklisted',
                           title: 'Is blacklisted',
                           list: [
                              {
                                 key: 1,
                                 description: 'true',
                              },
                              {
                                 key: 2,
                                 description: 'false',
                              },
                           ],
                        }}
                        handleInputChange={
                           onboardingActions?.handleInputChangeCompany
                        }
                     />
                  </div>
                  <div className="mt-1">
                     <Input
                        require={true}
                        infoInput={{
                           type: constants.inputType.NUMBER,
                           name: 'shareholders_count',
                           id: 'shareholders_count',
                        }}
                        label="Shareholders Count"
                        styles="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        handleInputChange={
                           onboardingActions?.handleInputChangeCompany
                        }
                     />
                  </div>
               </div>
               <div className="mt-8">
                  <Text
                     tag={constants.tagsTypes.P}
                     styles="text-lg leading-6 font-medium text-gray-900"
                     title="Others Information"
                  />
               </div>
               <div className="mt-6 grid sm:grid-cols-2 sm:gap-2 md:grid-cols-4 md:gap-4">
                  <div className="mt-1">
                     <Input
                        require={true}
                        infoInput={{
                           type: constants.inputType.FILE,
                           name: 'cif_proof',
                           id: 'cif_proof',
                        }}
                        label="Cif Proof"
                        styles="block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                        handleInputChange={(e) =>
                           onboardingActions?.Conver2Bse64CreateCompany(e)
                        }
                     />
                  </div>
                  <div className="mt-1">
                     <Input
                        require={true}
                        infoInput={{
                           type: constants.inputType.FILE,
                           name: 'address_proof',
                           id: 'address_proof',
                        }}
                        label="Address Proof"
                        styles="block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                        handleInputChange={(e) =>
                           onboardingActions?.Conver2Bse64CreateCompany(e)
                        }
                     />
                  </div>
                  <div className="mt-1">
                     <Input
                        require={true}
                        infoInput={{
                           type: constants.inputType.FILE,
                           name: 'constitutive_act_proof',
                           id: 'constitutive_act_proof',
                        }}
                        label="Constitutive Act Proof"
                        styles="block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                        handleInputChange={(e) =>
                           onboardingActions?.Conver2Bse64CreateCompany(e)
                        }
                     />
                  </div>
                  <div className="mt-1">
                     <Input
                        require={true}
                        infoInput={{
                           type: constants.inputType.FILE,
                           name: 'legal_representative_authorization_proof',
                           id: 'legal_representative_authorization_proof',
                        }}
                        label="Legal Representative Authorization Proof"
                        styles="block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                        handleInputChange={(e) =>
                           onboardingActions?.Conver2Bse64CreateCompany(e)
                        }
                     />
                  </div>
               </div>
            </div>
         </div>
         <div className="pt-5">
            <ButtonSubmit />
         </div>
      </form>
   );
}
