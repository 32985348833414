import React, { useEffect } from "react";
import { TableUsers } from "bitrus-web-ui";
import useUser from "../../Hooks/useUser";

const Users = () => {
  const { actionsUser, usersData } = useUser();

  useEffect(() => {
    actionsUser.getUsers(1, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getHeadersData = (obj) => {
    return Object.keys(obj[0]).map((data) => {
      return { Header: data, accessor: data };
    });
  };

  let argsTable = {
    columns: usersData.length ? getHeadersData(usersData) : [],
    data: usersData,
  };

  return (
    <>
      {argsTable?.data && (
        <div className="w-11/12 m-auto mt-5">
          <TableUsers {...argsTable} />
        </div>
      )}
    </>
  );
};

export default Users;
