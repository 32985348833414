import { Routes, Route, Navigate } from 'react-router-dom';
import Navbar from '../Components/Navbar';
import Overview from '../Pages/privates/Overview';
import Reports from '../Pages/privates/Reports';
import Users from '../Pages/privates/Users';
import TxHistory from '../Pages/privates/TxHistory';
import Onboarding from '../Pages/privates/Onboarding';
import { ConciliationsView } from '../Pages/privates/Conciliations/index';
import { ConciliationView } from '../Pages/privates/Conciliation/index';
import { ConciliationDeleteView } from '../Pages/privates/ConciliationDelete/index';
import { routes } from '../Utils/Constant/routes';

const DashboardRoutes = () => {
   return (
      <>
         <Navbar />
         <Routes>
            <Route path={routes.OVERVIEW} element={<Overview />} />
            <Route path={routes.REPORTS} element={<Reports />} />
            <Route path={routes.USERS} element={<Users />} />
            <Route path={routes.PNL} element={<ConciliationsView />} />
            <Route
               path={routes.REGISTERDEPOSIT}
               element={<ConciliationView />}
            />
            <Route
               path={routes.CONCILIATIONDELETE}
               element={<ConciliationDeleteView />}
            />
            <Route path={routes.TX_HISTORY} element={<TxHistory />} />
            <Route path={routes.ONBOARDING} element={<Onboarding />} />
            <Route
               path={routes.ERROR}
               element={<Navigate to={routes.OVERVIEW} />}
            />
         </Routes>
      </>
   );
};

export default DashboardRoutes;
