export const responses = {
 666: 'Algo salio mal, por favor intenta nuevamente más tarde',
 1001: 'Email o contraseña invalido',
 1002: 'Usuario desactivado',
 1003: 'Credenciales no válidas',
 1004: 'Token no valido',
 1005: 'Usuario no encontrado',
 1006: 'NIP invalido',
 1007: 'Usuario inactivo',
 1008: 'UNABLE_TO_START_RECOVERY ',
 1009: 'Operación no permitida',
 1010: 'NIP demasiado corto',
 1011: 'Usuario ya existe',
 1012: 'Usuario sin kyc pendiente',
 1013: 'Usuario no ha validado el numero telefónico',
 1014: 'Lugar de nacimiento no valido',
 1015: 'Nacionalidad no valida',
 1016: 'Ya se ha registrado un usuario con el mismo CURP',
 1017: 'Proceso de KYC denegado',
 1018: 'Datos faltantes',
 1019: 'Ya existe el dispositivo',
 1020: 'No se ha podido activar el usuario',
 1021: 'No se ha podido generar el código de verificación',
 1022: 'El usuario se ha aprobado',
 1023: 'El usuario ya tiene tipo',
 1024: 'El usuario tiene un código activo',
 1025: 'Contraseña demasiado débil',
 2001: 'Canal invalido',
 2002: 'Código de verificación no valido',
 2003: 'MFA no coincide',
 2004: 'Token invalido',
 2005: 'Credenciales no validas',
 2006: 'Recaptcha necesario',
 2007: 'Recaptcha no valido',
 2008: 'Endpoint para OTP invalido',
 2009: 'Demasiados intentos para reenvió',
 2010: 'Device ID requerido',

 3001: 'Api key invalida',
 3002: 'Api key inactiva',
 3003: 'Api key expirada',
 3004: 'Api key no encontrada',

 4001: 'Error de valor',
 4002: 'Error de validación',
 4003: 'Ruta no encontrada',
 4004: 'Parámetros inválidos',
 4005: 'Numero telefónico no valido',
 4006: 'País de numero telefónico no valido',
 4007: 'Fiat no encontrado',
 4008: 'Deposito no encontrado',
 4009: 'Asset no encontrado',
 4010: 'Mercado no encontrado',
 4011: 'Asset no soportado',
 4012: 'Asset no soportado por la red',
 4013: 'Servicio no disponible',
 4014: 'Servicio no encontrado',
 4015: 'Tipo invalido',
 4016: 'Municipio no encontrado',
 4017: 'Ocupación no encontrada',
 4018: 'Pagina de transición no encontrada',

 4019: 'Edad invalida',
 4020: 'Dispositivo invalido',
 4021: 'Monto invalido',
 4022: 'Dirección invalida',
 4023: 'Balance insuficiente',
 4024: 'Servicio invalido',
 4025: 'Fiat invalido',
 4026: 'Empresa no editable',
 4027: 'Empresa no encontrada ',
 4028: 'Empresa incompleta',
 4029: 'Limite excedido',
 4030: 'Limite de dispositivos alcanzado',
 4031: 'Banco no soportado',
 4032: 'Geolocalización requerida',
 4033: 'Geolocalización invalida',
 4034: 'Geolocalización incorrecta',
 5001: 'Error en la base de datos',
 5002: 'Envió de email no disponible',
 5003: 'Decode 64 no disponible',
 5004: 'Error en servicio',
 5005: 'Registro no disponible',
 5006: 'Envió de sms no disponible',
 5007: 'Depósitos suspendidos',
 5008: 'Retiros suspendidos',
 5009: 'No implementado',
 5010: 'Compras suspendidas',
 5011: 'Ventas suspendidas',

 6001: 'Firma requerida',
 6002: 'Campo no reconocido',
 6003: 'Edad con análisis de riesgo',
 6004: 'Esperando el servicio de KYC',
 6005: 'Firma requerida',
 6006: 'Firma invalida',

 7001: 'Registro fallido',
 7002: 'Registro duplicado',
 7003: 'KYC duplicado',
 7004: 'Error en Kyc',
 7005: 'Error en retiro SPEI',
 7006: 'Error en request de KLU',
 7007: 'Error en trading de KLU',
 7008: 'Error en balance de KLU',
};
