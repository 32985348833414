import apiInstance from './requestHelper';
import * as urls from './url';

export const accesToken = (data) => {
   return apiInstance(urls.accesToken, {
      method: 'POST',
      data,
   });
};

export const refreshToken = (data, location) => {
   return apiInstance(urls.refreshToken, {
      method: 'POST',
      data,
      location,
   });
};

export const mfaToken = (email, data) => {
   return apiInstance(urls.mfaToken(email), {
      method: 'POST',
      data,
   });
};

export const validatePin = (email, data) => {
   return apiInstance(urls.validatePin(email), {
      method: 'POST',
      data,
   });
};

export const testToken = (data) => {
   return apiInstance(urls.testToken, {
      method: 'POST',
      data,
   });
};

export const passwordRecovery = (email, data, location) => {
   return apiInstance(urls.passwordRecovery(email), {
      method: 'POST',
      data,
      location,
   });
};

export const accountRecovery = (email, data) => {
   return apiInstance(urls.accountRecovery(email), {
      method: 'POST',
      data,
   });
};

export const resendOtp = (email, data) => {
   return apiInstance(urls.resendOtp(email), {
      method: 'POST',
      data,
   });
};

export const resetPassword = (data, location) => {
   return apiInstance(urls.resetPassword, {
      method: 'POST',
      data,
      location,
   });
};

export const getUsers = (skip, limit) => {
   return apiInstance(urls.getUsers(skip, limit), {
      method: 'GET',
      token: true,
   });
};

export const postUsers = (data, location) => {
   return apiInstance(urls.postUsers, {
      method: 'POST',
      data,
      location,
   });
};

export const acceptTos = (data, location) => {
   return apiInstance(urls.acceptTos, {
      method: 'POST',
      data,
      location,
   });
};

export const sendKycInformation = (data, location) => {
   return apiInstance(urls.sendKycInformation, {
      method: 'PUT',
      data,
      location,
   });
};

export const getUserMe = () => {
   return apiInstance(urls.getUserMe, {
      method: 'GET',
      token: true,
   });
};

export const putUserMe = (data, location) => {
   return apiInstance(urls.putUserMe, {
      method: 'PUT',
      data,
      location,
   });
};

export const setUsersPhone = (data, location) => {
   return apiInstance(urls.setUsersPhone, {
      method: 'POST',
      data,
      location,
   });
};

export const registerDevice = (data, location) => {
   return apiInstance(urls.registerDevice, {
      method: 'POST',
      data,
      location,
   });
};

export const removeDevice = (data, location) => {
   return apiInstance(urls.removeDevice, {
      method: 'POST',
      data,
      location,
   });
};

export const signUp = (data, location) => {
   return apiInstance(urls.signUp, {
      method: 'POST',
      data,
      location,
   });
};

export const validateChannels = (data, location) => {
   return apiInstance(urls.validateChannels, {
      method: 'POST',
      data,
      location,
   });
};

export const generateOtp = (data) => {
   return apiInstance(urls.generateOtp, {
      method: 'POST',
      data,
   });
};

export const getUser = (user_id) => {
   return apiInstance(urls.getUser(user_id), {
      method: 'GET',
   });
};

export const putUser = (data, user_id, location) => {
   return apiInstance(urls.putUser(user_id), {
      method: 'PUT',
      data,
      location,
   });
};

export const setUserPhone = (data, user_id, location) => {
   return apiInstance(urls.setUserPhone(user_id), {
      method: 'POST',
      data,
      location,
   });
};

export const cryptobalance = () => {
   return apiInstance(urls.cryptobalance, {
      method: 'GET',
   });
};

export const usersMeTx = () => {
   return apiInstance(urls.usersMeTx, {
      method: 'GET',
   });
};

export const userTx = (user_id) => {
   return apiInstance(urls.userTx(user_id), {
      method: 'GET',
   });
};

export const reviewKycRiskProfile = (data, user_id, location) => {
   return apiInstance(urls.reviewKycRiskProfile(user_id), {
      method: 'POST',
      data,
      location,
   });
};

export const listenMatiStep = (data, location) => {
   return apiInstance(urls.listenMatiStep, {
      method: 'POST',
      data,
      location,
   });
};

export const transferColdStorage = (data) => {
   return apiInstance(urls.transferColdStorage, {
      method: 'POST',
      data,
   });
};

export const balance = () => {
   return apiInstance(urls.balanceAdmin, {
      method: 'GET',
      token: true,
   });
};

export const adminTx = (start, end) => {
   return apiInstance(urls.adminTx(start, end), {
      method: 'GET',
      token: true,
   });
};

export const getTotalUsersBalance = () => {
   return apiInstance(urls.getTotalUsersBalance, {
      method: 'GET',
   });
};

export const conciliateDeposits = (data, location) => {
   return apiInstance(urls.conciliateDeposits, {
      method: 'POST',
      data,
      location,
      token: true,
   });
};

export const deleteDepositPreview = (deposit_id) => {
   return apiInstance(urls.deleteDepositPreview(deposit_id), {
      method: 'POST',
      token: true,
   });
};

export const deleteDeposit = (deposit_id) => {
   return apiInstance(urls.deleteDeposit(deposit_id), {
      method: 'POST',
      token: true,
   });
};

export const buyOrders = () => {
   return apiInstance(urls.buyOrders, {
      method: 'GET',
      token: true,
   });
};

export const deposits = () => {
   return apiInstance(urls.deposits, {
      method: 'GET',
      token: true,
   });
};

export const conciliationStatus = () => {
   return apiInstance(urls.conciliationStatus, {
      method: 'GET',
      token: true,
   });
};

export const setDefaultWithdraw = (data, asset_network_id) => {
   return apiInstance(urls.setDefaultWithdraw(asset_network_id), {
      method: 'POST',
      data,
   });
};

export const getDefaultWithdraw = (asset_network_id) => {
   return apiInstance(urls.conciliationStatus(asset_network_id), {
      method: 'GET',
   });
};

export const setAssetNetworkActivity = (data, location, asset_network_id) => {
   return apiInstance(urls.setAssetNetworkActivity(asset_network_id), {
      method: 'POST',
      data,
      location,
   });
};

export const setMarketActivity = (data, location, market_id) => {
   return apiInstance(urls.setMarketActivity(market_id), {
      method: 'POST',
      data,
      location,
   });
};

export const generateUifReport = (data) => {
   return apiInstance(urls.generateUifReport, {
      method: 'POST',
      data,
   });
};

export const generatePLreports = (data) => {
   return apiInstance(urls.generatePLreports, {
      method: 'POST',
      data,
   });
};

export const DepositMarketMaker = (data) => {
   return apiInstance(urls.marketmaker, {
      method: 'POST',
      data,
      token: true,
   });
};

export const addExternalCompany = (data, id) => {
   return apiInstance(urls.addExternalCompany(id), {
      method: 'POST',
      data,
      token: true,
   });
};
export const addPeson = (data, id) => {
   return apiInstance(urls.addPeson(id), {
      method: 'POST',
      data,
      token: true,
   });
};
export const createCompany = (data, company_id) => {
   return apiInstance(urls.createUpdateCompany(company_id), {
      method: 'PUT',
      data,
      token: true,
   });
};

export const createSharedHolder = (data, company_id) => {
   return apiInstance(urls.createSharedHolder(company_id), {
      method: 'POST',
      data,
      token: true,
   });
};

export const createEmployees = (data, company_id) => {
   return apiInstance(urls.createEmployes(company_id), {
      method: 'POST',
      data,
      token: true,
   });
};

export const createLegalRepresentative = (data, company_id) => {
   return apiInstance(urls.createLegalRepresentative(company_id), {
      method: 'POST',
      data,
      token: true,
   });
};
export const companyType = () => {
   return apiInstance(urls.companyType, {
      method: 'GET',
      token: true,
   });
};
export const companyActivity = () => {
   return apiInstance(urls.companyActivity, {
      method: 'GET',
      token: true,
   });
};
export const financialEntity = () => {
   return apiInstance(urls.financialEntity, {
      method: 'GET',
      token: true,
   });
};
export const fundOrigin = () => {
   return apiInstance(urls.fundOrigin, {
      method: 'GET',
      token: true,
   });
};
export const zipCode = (zip_code_id) => {
   return apiInstance(urls.zipCode(zip_code_id), {
      method: 'GET',
      token: true,
   });
};

export const setShareholdersExternal = (
   data,
   company_id,
   external_company_id
) => {
   return apiInstance(
      urls.setShareholdersExternal(company_id, external_company_id),
      {
         method: 'POST',
         data,
         token: true,
      }
   );
};
