import jwt_decode from 'jwt-decode';
import dayjs from 'dayjs';
import axios from 'axios';
import { base } from './url';
import * as urls from './url';
import { USER_LOGIN } from '../Utils/Constant/storage';

export const validateToken = (ACCESS_TOKEN) => {
   if (ACCESS_TOKEN !== undefined) {
      let decode = jwt_decode(ACCESS_TOKEN);
      let isExpired = dayjs.unix(decode.exp).diff(dayjs()) < 1;
      return isExpired;
   }
   return true;
};

export const refreshToken = async (refresh_token) => {
   let response = await axios.post(`${base}${urls.refreshToken}`, {
      refresh_token: refresh_token,
   });
   localStorage.setItem(
      USER_LOGIN,
      JSON.stringify({
         ACCESS_TOKEN: response.data.access_token,
         REFRESH_TOKEN: refresh_token,
      })
   );
   return response.data.access_token;
};
