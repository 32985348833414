import { base } from '../helpers/url';
import axios from 'axios';
import { refreshToken, validateToken } from './validateToken';
import { USER_LOGIN } from '../Utils/Constant/storage';

const apiInstance = axios.create({ baseURL: base });

apiInstance.interceptors.request.use(
   async (request) => {
      const { headers } = request;
      let accessToken = localStorage.getItem(USER_LOGIN)
         ? JSON.parse(localStorage.getItem(USER_LOGIN))
         : null;

      headers['X-BITRUS-MFA'] = request.MFA ?? null;
      headers['B-APP-LOCATION'] = request.location
         ? `${request.location.latitud}: ${request.location.longitud}`
         : null;

      if (request.token) {
         let isExpired = validateToken(accessToken?.ACCESS_TOKEN);
         if (isExpired) {
            const resp = await refreshToken(accessToken?.REFRESH_TOKEN);
            headers.Authorization = `Bearer ${resp}`;
         } else {
            headers.Authorization = `Bearer ${accessToken?.ACCESS_TOKEN}`;
         }
      }
      headers['Content-Type'] = 'application/json';
      return request;
   },
   (error) => {
      return Promise.reject(error.response.data);
   }
);

apiInstance.interceptors.response.use(
   function (response) {
      return response.data;
   },
   function (error) {
      return Promise.reject(error.response.data);
   }
);
export default apiInstance;
