import { constants, Input, SelectMenu, Text } from 'bitrus-web-ui';
import React, { useState } from 'react';
import { useOnboarding } from '../../Hooks/useOnboarding';
import { ButtonSubmit } from '../ButtonSubmit';
import { ButtonReset } from '../ButtonReset';
import { typeUser } from '../../Utils/Constant//typeUser';
import { SelectMenusTypes } from '../SelectMenusTypes';

export const SetShareholdersExternal = () => {
   const { onboardingActions, dataStore } = useOnboarding();
   const [sharedHolders, setsharedHolders] = useState({
      external_company_id: 0,
      shareholder_type: '',
      shareholder_id: 0,
      company_percentage: 0,
   });
   const handleInputChange = (event) => {
      setsharedHolders({
         ...sharedHolders,
         [event?.target.name]: event?.target.value,
      });
   };
   const handleSubmit = (e) => {
      e.preventDefault();
      let newSharedHolders = [
         {
            ...sharedHolders,
            shareholder_id: parseInt(sharedHolders.shareholder_id),
            company_percentage: parseInt(sharedHolders.company_percentage),
         },
      ];
      onboardingActions.setShareholdersExternal(
         newSharedHolders,
         sharedHolders.external_company_id
      );
   };
   return (
      <form
         className="sm:m-16 space-y-5 divide-y divide-gray-200"
         onSubmit={handleSubmit}
      >
         <div className="space-y-8 divide-y divide-gray-200">
            <div>
               <div>
                  <Text
                     tag={constants.tagsTypes.P}
                     styles="text-lg leading-6 font-medium text-gray-900"
                     title="Set shareholders"
                  />
               </div>
            </div>
         </div>
         <div className="pt-8">
            <div className="mt-1">
               <SelectMenu
                  options={{
                     selectIcon: false,
                     icon: false,
                     name: 'shareholder_type',
                     id: 'shareholder_type',
                     title: 'Shareholder type',
                     list: [
                        {
                           key: 1,
                           description: typeUser.PERSON,
                        },
                        {
                           key: 2,
                           description: typeUser.COMPANY,
                        },
                     ],
                  }}
                  handleInputChange={handleInputChange}
               />
            </div>
            <div className="mt-1">
               <SelectMenusTypes
                  type={sharedHolders.shareholder_type}
                  handleInputChange={handleInputChange}
               />
               <div className="mt-1">
                  <SelectMenu
                     options={{
                        selectIcon: false,
                        icon: false,
                        name: 'external_company_id',
                        id: 'external_company_id',
                        title: ' Select external company',
                        list: dataStore.dataExternalCompany.map((data) => {
                           return {
                              key: data.id,
                              value: data.id,
                              description: data.business_name,
                           };
                        }),
                     }}
                     handleInputChange={handleInputChange}
                  />
               </div>
            </div>
            <div className="mt-1">
               <Input
                  require={true}
                  infoInput={{
                     type: constants.inputType.TEXT,
                     name: 'company_percentage',
                     id: 'company_percentage',
                  }}
                  label="Company percentage"
                  styles="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  handleInputChange={handleInputChange}
               />
            </div>
         </div>
         <div className="pt-5">
            <ButtonSubmit />
         </div>
         <div className="pt-5">
            <ButtonReset />
         </div>
      </form>
   );
};
